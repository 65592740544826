import React from 'react'
import styled from 'styled-components';
import { TimelineMax, TweenMax, Power2 } from "gsap";

import { colors } from "../vars/globalVars";

import CloserLogo from "../assets/CloserLogo";

const Container = styled.section`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.logoRef = React.createRef();
  }

  componentDidMount() {
    // Set defaultEase
    TweenMax.defaultEase = Power2.easeIn;

    let timeline = new TimelineMax();

    timeline
      .set("#LandingPage .CloserLogo path.e", {
        scaleX: -1,
        transformOrigin: "50% 50%"
      })
      .fromTo("#LandingPage .CloserLogo", 0.5, {
        transform: "translateY(-100%)",
        opacity: 0,
        ease: Power2.easeOut
      }, {
        transform: "translateY(20px)",
        opacity: 1
      })
      .to("#LandingPage .CloserLogo", 0.3, {
        transform: "translateY(0)"
      })
      .to("#LandingPage .CloserLogo path.e", 0.3, {
        scaleX: 1,
        transformOrigin: "50% 50%"
      }, "-=0.3");
  }

  render() {
    return (
      <Container id="LandingPage">
        <CloserLogo color={colors.skyBlue} />
      </Container>
    )
  }
}
