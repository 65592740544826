import React from "react";

import { colors } from "../vars/globalVars";

export default class MadeByLateral extends React.Component {
  render() {
    return (
      <svg width={146} height={13}>
        <g fill={colors.darkGreen} fillRule="evenodd">
          <text
            fontFamily="Galano"
            fontSize={12}
            fontWeight={400}
            transform="translate(-185 -855)">
            <tspan x={185} y={865}>
              Made by
            </tspan>
          </text>
          <path d="M145.838 7.712h-4.692V.21a.163.163 0 0 0-.162-.16h-2.136a.163.163 0 0 0-.162.16v9.58c0 .088.073.16.162.16h6.99a.163.163 0 0 0 .162-.16V7.872a.163.163 0 0 0-.162-.16m-34.226-2.996v-2.43c1.18-.032 3.478-.347 3.478 1.232-.095 1.533-2.29 1.229-3.478 1.198m3.98 1.732l2.287 3.261c.072.103-.025.242-.142.242h-2.307c-.495 0-.661-.53-.917-.905l-1.469-2.149a.01.01 0 0 1-.002-.003.34.34 0 0 1-.026.001h-1.404V9.79c0 .088-.075.16-.163.16h-2.124a.163.163 0 0 1-.163-.16V.2c0-.088.075-.16.163-.16h4.428c1.238 0 2.686.378 3.353 1.507.683 1.155.68 2.731-.114 3.834-.005.007-.011.013-.018.019-.002.004-.004.009-.008.013-.38.473-.829.798-1.373 1.036zM74.683.163l4.082 9.368c.092.21-.01.469-.273.469h-2.19c-.153 0-.25-.098-.304-.229l-2.219-5.306h-.001s-.144-.34-.35-.34h-.001c-.206 0-.351.34-.351.34l-.003-.005-2.203 5.311c-.054.13-.152.229-.305.229H68.42c-.262 0-.364-.26-.273-.469L72.218.163c.063-.144.186-.183.301-.154h1.89c.1 0 .23.057.273.154zm-11.53 7.548c.089 0 .163.073.163.161V9.79c0 .088-.074.16-.163.16h-6.99A.163.163 0 0 1 56 9.79V.21c0-.088.074-.16.163-.16h2.135c.045 0 .078.016.104.039l.01.008.01.011c.022.026.039.058.039.102v7.501h4.69zM90.194.05c.088 0 .162.074.162.161v1.964c0 .087-.074.161-.162.161h-2.801V9.79c0 .088-.074.16-.163.16h-2.124a.163.163 0 0 1-.162-.16V2.335H82.13a.15.15 0 0 1-.104-.04.06.06 0 0 1-.011-.008l-.008-.011a.144.144 0 0 1-.04-.102V.21c0-.087.074-.16.163-.16h8.063zm39.577.114c1.36 3.123 2.722 6.245 4.083 9.368.091.21-.011.469-.273.469h-2.19c-.153 0-.25-.098-.305-.229l-2.219-5.306s-.145-.34-.351-.34-.352.34-.352.34l-.002-.005-2.203 5.311c-.054.13-.152.229-.305.229h-2.144c-.262 0-.364-.26-.274-.469l4.072-9.368c.063-.144.185-.183.3-.154h1.89c.1 0 .231.057.273.154zM103.188 7.77c.089 0 .163.073.163.16v1.873a.164.164 0 0 1-.163.16h-7.42a.163.163 0 0 1-.163-.16V.199c0-.088.073-.16.163-.16h7.362c.09 0 .162.072.162.16v1.883a.163.163 0 0 1-.162.161h-5.064V3.84h4.434c.088 0 .163.073.163.16v1.885c0 .087-.075.16-.163.16h-4.434V7.77h5.122z" />
        </g>
      </svg>
    );
  }
}
