import React from 'react';
import { colors } from '../vars/globalVars';
import background from '../assets/img/Vertical_Illustration_Home.png';
import Footer from "../components/Absolute/Footer";

const Intro = () => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundImage: "url(" + background + ")",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    justifyContent: 'flex-end',
  }}>
    <h1 style={{
      alignSelf: 'flex-end',
      textAlign: 'left',
      padding: '0 20px',
      marginBottom: '50px',
      fontSize: '29px',
      letterSpacing: '0px',
      color: colors.white
    }}
    >
      The game where you win each other.
    </h1>
    <Footer color={colors.white} />
  </div>
);

export default Intro;
