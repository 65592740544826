import React from "react";

import { colors } from "../vars/globalVars";

export default class CloserLogo extends React.Component {
  render() {
    const props = this.props;

    let width = 245,
      height = 50;

    if (props.xsmall) {
      width = 65;
      height = 13;
    }

    if (props.mobile) {
      width = 74;
      height = 15;
    }

    if (props.small) {
      width = 98;
      height = 20;
    }

    return (
      <svg
        className={props.isCardBack ? "CloserCardLogo" : "CloserLogo"}
        width={width}
        height={height}
        viewBox="0 0 245 50"
        xmlns="http://www.w3.org/2000/svg">
        <path
          className="c"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8216 15.9599C24.1812 15.9599 27.3452 17.2831 29.7307 19.6869L35.4758 13.7925C31.5518 9.83882 26.3473 7.66077 20.8216 7.66077C9.34004 7.66077 0 17.1582 0 28.831C0 40.5038 9.34004 49.9995 20.8216 49.9995C26.5281 49.9995 31.7036 47.6528 35.4688 43.8606L29.7307 37.9742L29.7324 37.9608C27.4435 40.27 24.2945 41.7013 20.8216 41.7013C13.8407 41.7013 8.16222 35.9273 8.16222 28.831C8.16222 21.7338 13.8407 15.9599 20.8216 15.9599"
          fill={props.color || colors.white}
        />
        <path
          className="l"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.6406 49.3627H56.8028V0H48.6406V49.3627Z"
          fill={props.color || colors.white}
        />
        <path
          className="o"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.9688 28.8306C69.9688 17.1578 79.3088 7.66125 90.7903 7.66125C102.272 7.66125 111.612 17.1578 111.612 28.8306C111.612 40.5034 102.272 50 90.7903 50C79.3088 50 69.9688 40.5034 69.9688 28.8306ZM78.1309 28.8306C78.1309 35.9269 83.8093 41.7009 90.7902 41.7009C97.7711 41.7009 103.45 35.9269 103.45 28.8306C103.45 21.7334 97.7711 15.9595 90.7902 15.9595C83.8093 15.9595 78.1309 21.7334 78.1309 28.8306Z"
          fill={props.color || colors.white}
        />
        <path
          className="s"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.378 26.6506L137.161 24.5868C134.035 23.8444 132.473 22.3623 132.473 20.1388C132.473 18.6567 133.059 17.4254 134.231 16.4456C135.403 15.4668 137.108 14.9769 139.348 14.9769C141.795 14.9769 143.8 15.5587 145.362 16.724C146.925 17.8893 147.862 19.4508 148.174 21.4094L155.908 19.7417C155.179 16.3528 153.369 13.6135 150.479 11.522C147.589 9.43052 143.956 8.38477 139.582 8.38477C134.947 8.38477 131.236 9.5367 128.451 11.8397C125.664 14.1427 124.271 17.1479 124.271 20.8535C124.271 26.4124 127.786 29.986 134.817 31.5751L143.253 33.3222C146.743 34.1163 148.487 35.6778 148.487 38.0076C148.487 39.5958 147.797 40.8673 146.417 41.8194C145.037 42.7724 143.175 43.2497 140.832 43.2497C138.123 43.2497 135.898 42.6929 134.153 41.5812C132.408 40.4694 131.406 38.8017 131.146 36.5781L123.021 38.3252C123.49 42.1371 125.313 45.0352 128.489 47.0214C131.666 49.0067 135.546 49.9998 140.129 49.9998C145.076 49.9998 149.034 48.888 152.003 46.6645C154.971 44.44 156.455 41.317 156.455 37.2929C156.455 34.5402 155.569 32.2497 153.799 30.4241C152.028 28.5967 149.555 27.3386 146.378 26.6506"
          fill={props.color || colors.white}
        />
        <path
          className="e"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.861 13.3793C177.629 9.6005 182.24 7.68567 187.564 7.68567C193.253 7.68567 198.128 9.6817 202.053 13.6184C205.979 17.557 207.97 22.7019 207.97 28.9094C207.97 35.0644 205.979 40.1691 202.052 44.0818C198.127 47.9918 193.358 49.9744 187.878 49.9744C183.459 49.9744 179.548 48.9019 176.254 46.7854C174.499 45.6576 172.984 44.2237 171.706 42.5149L178.612 38.5836C180.849 41.0712 183.951 42.3302 187.878 42.3302C190.79 42.3302 193.38 41.2773 195.576 39.1992C197.515 37.3656 198.746 34.9475 199.257 32.0083H168.069C167.865 30.636 167.865 27.944 167.865 27.944C168.077 22.0558 170.094 17.1563 173.861 13.3793ZM180.561 17.9112C178.682 19.4709 177.407 21.7497 176.771 24.6889H199.144C198.548 21.8577 197.145 19.6074 194.968 17.9995C192.716 16.3336 190.224 15.4895 187.564 15.4895C184.855 15.4895 182.499 16.305 180.561 17.9112Z"
          fill={props.color || colors.white}
        />
        <path
          className="r"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M242.267 9.49524C235.966 9.49524 231.045 12.4603 227.503 18.3904V9.97172H219.379V49.3632H227.503V35.4642C227.503 29.9053 228.753 25.5509 231.253 22.4012C233.752 19.2505 237.397 17.6757 242.188 17.6757C243.282 17.6757 244.038 17.7015 244.454 17.7551L245 9.81289C244.325 9.60142 243.413 9.49524 242.267 9.49524"
          fill={props.color || colors.white}
        />
      </svg>
    );
  }
}
