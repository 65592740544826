import { Linear } from "gsap";

import { colors } from "../vars/globalVars";

export const climbingImages = [
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00000.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00001.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00002.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00003.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00004.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00005.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00006.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00007.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00008.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00009.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00010.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00011.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00012.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00013.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00014.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00015.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00016.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00017.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00018.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00019.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00020.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00021.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00022.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00023.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00024.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00025.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00026.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00027.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00028.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00029.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00030.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00031.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00032.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00033.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00034.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00035.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00036.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00037.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00038.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00039.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00040.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00041.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00042.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00043.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00044.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00045.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00046.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00047.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00048.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00049.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00050.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00051.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00052.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00053.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00054.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00055.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00056.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00057.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00058.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00059.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00060.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00061.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00062.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00063.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00064.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00065.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00066.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00067.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00068.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00069.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00070.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00071.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00072.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00073.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00074.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00075.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00076.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00077.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00078.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00079.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00080.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00081.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00082.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00083.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00084.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00085.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00086.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00087.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00088.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00089.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00090.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00091.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00092.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00093.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00094.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00095.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00096.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00097.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00098.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00099.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00100.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00101.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00102.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00103.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00104.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00105.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00106.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00107.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00108.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00109.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00110.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00111.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00112.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00113.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00114.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00115.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00116.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00117.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00118.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00119.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00120.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00121.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00122.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00123.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00124.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00125.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00126.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00127.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00128.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00129.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00130.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00131.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00132.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00133.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00134.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00135.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00136.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00137.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00138.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00139.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00140.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00141.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00142.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00143.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00144.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00145.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00146.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00147.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00148.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00149.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00150.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00151.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00152.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00153.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00154.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00155.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00156.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00157.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00158.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00159.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00160.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00161.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00162.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00163.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00164.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00165.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00166.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00167.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00168.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00169.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00170.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00171.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00172.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00173.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00174.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00175.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00176.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00177.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00178.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00179.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00180.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00181.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00182.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00183.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00184.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00185.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00186.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00187.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00188.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00189.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00190.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00191.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00192.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00193.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00194.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00195.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00196.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00197.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00198.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00199.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00200.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00201.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00202.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00203.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00204.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00205.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00206.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00207.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00208.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00209.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00210.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00211.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00212.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00213.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00214.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00215.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00216.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00217.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00218.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00219.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00220.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00221.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00222.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00223.png",
  process.env.PUBLIC_URL + "/climber-sprites/MAIN_00224.png"
];

export default tl => {
  tl
    // Reset active classes
    // --------------------
    .to("#HeaderMenu .Menu-Item", 0, { className: "-=active" }, "-=0.7")

    // Pre-animation prep
    // ------------------
    .set("#ClimbingStairs", { display: 'flex' })

    .fromTo("#ClimbingStairsImg0", 1, { opacity: 0, top: "100%" }, { display: "block", opacity: 1, top: 0 })
    .to("#Background1", 0, { backgroundColor: colors.purple })
    .to("#Background2", 0, { backgroundColor: colors.stairsPurple, x: "0%" });

  for (let i = 1; i < climbingImages.length;  i++){
    tl
      .to(`#ClimbingStairs .ClimbingStairsImg:not(#ClimbingStairsImg${i})`, 0, {
        display: "none",
        opacity: 0,
        ease: Linear.easeNone,
        delay: 0.02
      })
      .to(`#ClimbingStairsImg${i}`, 0, {
        display: "block",
        opacity: 1,
        ease: Linear.easeNone
      });
    if(i % 50 === 0) {
      tl.addPause();
    }
  }

  tl.to("#ClimbingStairsImg224", 1, { display: "none", opacity: 0, top: "100%" });

  return tl;
};
