export const colors = {
  white: "#fff",
  offWhite: "#ffe1e1",
  lightBlue: "#dbf1fc",
  skyBlue: "#1852A0",
  darkBlue: "#0f113c",
  darkPurple: "#242260",
  stairsPurple: "#100868",
  purple: "#2b2e7e",
  orange: "#dd4227",
  lightTeal: "#c8e0df",
  lighterTeal: "#daeceb",
  teal: "#1a6561",
  darkTeal: "#395f56",
  pink: "#ed1f56",
  pinkish: "#ea8a98",
  veryPink: "#fae1f5",
  tomato: "#ee4545",
  darkTomato: "#db343b",
  cream: "#f4f3f0",
  darkCream: "#E3E1DB",
  vanilla: "#ffe9db",
  darkVanilla: "#ffe1cf",
  brown: "#b87c58",
  shadow: "#e0dddb",
  darkGreen: "#757c73",
  darkGrey: "#282828",
  yellow: "#edd84a"
};
