import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

const Svg = styled.svg`
  width: 100%;
  max-width: 789px;
  height: 100%;
  max-height: 519px;

	.st0{
    fill: ${colors.shadow};
  }
	.st1{
    fill: none;
    stroke: ${colors.darkTeal};
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
	.st2{
    fill: ${colors.darkTeal};
  }
`;

export default class HowToPlayImg4 extends React.Component {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        viewBox="0 0 1580 1280"
        xmlSpace="preserve" >
        <path
          className="st0"
          d="M1127.3,680c-8.2,35.5-19.7,48.2-43.1,66.8c5.3,10.5,6,18.4,7.4,34.5c25.1-23.1,39.4-49.3,39.4-77 C1131,696,1129.7,687.9,1127.3,680z"
        />
        <path
          className="st0"
          d="M465.4,744.3c-21.7-18-33.1-35.2-40.4-61.1c-12.1,36,10,70.8,31.5,92.5C458.5,764.7,458,756.8,465.4,744.3z"
        />
        <path
          className="st0"
          d="M776.5,829.9c-38.5,0.1-76.9-2.9-114.9-9c2.5,15.1,4.9,25.8,6.8,43.6c35.8,5.4,71.9,8,108.1,8 c34.2,0,68.3-2.3,102.2-7.1c1.6-17.2,3.5-28.1,5.5-43.3C848.5,827.4,812.5,830,776.5,829.9z"
        />
        <path
          className="st1"
          d="M1087.2,745.3c26.5-22.2,41.5-47.6,41.5-74.6c0-87.5-157.7-158.4-352.2-158.4s-352.2,70.9-352.2,158.4 c0,25.6,13.5,49.7,37.4,71.1l4,3.5"
        />
        <path
          className="st1"
          d="M881.2,822c-34.7,4.8-69.7,7.2-104.8,7.1c-36.5,0-79.4-3.6-112.5-8.2"
        />
        <path
          className="st1"
          d="M1059.2,576.3c-1.6-36-2.6-71.7-3.3-97.7c-0.6-23.3-17.3-43-40.1-47.6l-5.2-1c0.7-12,0.8-24.1,0.2-36.2 c-1-17.5-12.3-45.7-33.3-32.4s2.7,64.8,2.7,64.8l0,0l-17.5-1c-12-0.7-24,4.3-30.8,14.2c-19.5,28.2-19.3,85.2-19.3,85.2"
        />
        <path
          className="st1"
          d="M493.9,576.3c1.5-36,2.6-71.7,3.3-97.7c0.6-23.3,17.3-43,40.1-47.6l5.2-1c-0.7-12-0.8-24.1-0.2-36.2 c1-17.5,12.3-45.7,33.3-32.4s-2.7,64.8-2.7,64.8l0,0l17.5-1c12-0.7,24,4.3,30.8,14.2c19.5,28.2,19.3,85.2,19.3,85.2"
        />
        <line
          className="st1"
          x1="515.6" y1="964.5" x2="515.6" y2="964.5"
        />
        <path
          className="st1"
          d="M673.9,973.7c0,0-0.3-23.6-1.8-56c-2.8-58.4-9.9-145.1-27.3-173.5c-14.4-23.5-73-41.9-73-41.9l0,0 c0,0,31.8-111.2-17.6-111.2c-64.3,0-28.3,105.4-28.3,105.4s-64.4,19-68.2,79.2"
        />
        <path
          className="st1"
          d="M671.7,899.4l23.4-18.3c22.3-17.5,54.9-1.6,54.9,26.7v118.7"
        />
        <path
          className="st1"
          d="M874.9,973.7c0,0,0.3-23.6,1.8-56c2.8-58.4,9.9-145.1,27.3-173.5c14.4-23.5,73-41.9,73-41.9l0,0 c0,0-31.8-111.2,17.6-111.2c64.3,0,28.3,105.4,28.3,105.4s68.4,20.1,68.4,84.8"
        />
        <path
          className="st1"
          d="M877,899.4l-23.3-18.3c-22.3-17.5-54.9-1.6-54.9,26.7v118.7"
        />
        <path
          className="st1"
          d="M446.1,973.7l-17-161.2c-1.8-17.3,10.7-32.8,28-34.6c4-0.4,8.1-0.1,11.9,1l73.4,20.8 c26,7.3,44.8,29.9,47.3,56.8l15.6,165.2"
        />
        <path
          className="st1"
          d="M1126.3,973.7l17-161.2c1.8-17.3-10.7-32.8-28-34.6c-4-0.4-8-0.1-11.9,1l-73.4,20.8 c-26,7.4-44.8,29.9-47.3,56.8l-15.6,165.2"
        />
        <path
          className="st1"
          d="M412,548.2c-53.1-31.7-84.4-70.6-84.4-112.5c0-107,202.9-193.7,453.2-193.7c191,0,354.3,50.5,421.1,121.9"
        />
        <polygon
          className="st2"
          points="1193.7,367.7 1210.1,373.2 1206.7,356.3 "
        />
        <path
          className="st0"
          d="M1000,1011.7l14.2-141.6c2.3-22.7,18.5-41.5,40.7-47.1l58.7-14.7c7.1-1.8,14.2,2.5,16,9.6 c0.4,1.5,0.5,3.1,0.3,4.6l-17,154.1L1000,1011.7z"
        />
        <path
          className="st0"
          d="M573.3,1011.7l-14.2-141.6c-2.3-22.7-18.5-41.5-40.7-47.1l-58.7-14.7c-7.1-1.8-14.2,2.5-16,9.6 c-0.4,1.5-0.5,3.1-0.3,4.6l17,154.1L573.3,1011.7z"
        />
      </Svg>
    );
  }
}
