import React from 'react';
import styled from "styled-components";
import { PageSeparator } from "../MobileApp";
import { colors } from "../vars/globalVars";
import HowToPlayImg1 from "../assets/HowToPlayImg1";
import HowToPlayImg2 from "../assets/HowToPlayImg2";
import HowToPlayImg3 from "../assets/HowToPlayImg3";
import HowToPlayImg4 from "../assets/HowToPlayImg4";
import HowToPlayImg5 from "../assets/HowToPlayImg5";

const Hero = styled.h1`
  color: ${colors.darkTeal};
  margin: 0 0 10px;
  font-size: 45px;
  font-weight: 500;
`;

const HeroImg = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileProgress = styled.div`
  height: 15%;
  margin-top: -20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

const BottomProgressSteps = styled.div`
  font-family: "Belwe";
  text-align: center;
`;

const CurrentStep = styled.span`
  left: 7%;
`;

const BottomLegend = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  line-height: 45px;
`;

const LegendItem = styled.div`
  color: rgba(57, 95, 86, 0.8);
  cursor: pointer;
`;

const HowToPlay = ({ setHeaderColor }) => (
  <div
    className="mobile-content"
    style={{textAlign: 'center'}}
  >
    <Hero>How To Play</Hero>

    <HeroImg>
      <HowToPlayImg1 />
    </HeroImg>
    <MobileProgress>
      <BottomProgressSteps>
        <CurrentStep>1</CurrentStep>
        <span> / 5</span>
      </BottomProgressSteps>
      <BottomLegend>
        <LegendItem>Draw 5 cards</LegendItem>
      </BottomLegend>
    </MobileProgress>


    <HeroImg>
      <HowToPlayImg2 />
    </HeroImg>
    <MobileProgress>
      <BottomProgressSteps>
        <CurrentStep>2</CurrentStep>
        <span> / 5</span>
      </BottomProgressSteps>
      <BottomLegend>
        <LegendItem>Start conversations</LegendItem>
      </BottomLegend>
    </MobileProgress>


    <HeroImg>
      <HowToPlayImg3 />
    </HeroImg>
    <MobileProgress>
      <BottomProgressSteps>
        <CurrentStep>3</CurrentStep>
        <span> / 5</span>
      </BottomProgressSteps>
      <BottomLegend>
        <LegendItem>Draw more cards</LegendItem>
      </BottomLegend>
    </MobileProgress>


    <HeroImg>
      <HowToPlayImg4 />
    </HeroImg>
    <MobileProgress>
      <BottomProgressSteps>
        <CurrentStep>4</CurrentStep>
        <span> / 5</span>
      </BottomProgressSteps>
      <BottomLegend>
        <LegendItem>Play continues clockwise</LegendItem>
      </BottomLegend>
    </MobileProgress>


    <HeroImg>
      <HowToPlayImg5 />
    </HeroImg>
    <MobileProgress style={{ marginBottom: 0 }}>
      <BottomProgressSteps>
        <CurrentStep>5</CurrentStep>
        <span> / 5</span>
      </BottomProgressSteps>
      <BottomLegend>
        <LegendItem>Vote the winners</LegendItem>
      </BottomLegend>
    </MobileProgress>


    <PageSeparator
      position={{position: 'absolute', bottom: 50}}
      onEnterViewport={() => setHeaderColor(colors.darkTeal)}
    />
  </div>
);

export default HowToPlay;
