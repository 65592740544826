import React from "react";

import { colors } from "../vars/globalVars";

export default class MCSurprising extends React.Component {
  render() {
    return (
      <svg width={50} height={62} xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <path id="a" d="M0 .367h43.819V58H0z" />
          <path id="c" d="M.661.425H35.82V58H.661z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M23.604 37.656a8.921 8.921 0 0 1-6.746-1.39 8.937 8.937 0 0 1-3.787-5.754 8.943 8.943 0 0 1 1.389-6.747 8.938 8.938 0 0 1 5.753-3.787 9.047 9.047 0 0 1 1.699-.162c4.229 0 8.01 3.002 8.836 7.306.934 4.873-2.27 9.598-7.144 10.534m-3.768-19.643a10.938 10.938 0 0 0-7.032 4.63 10.931 10.931 0 0 0-1.698 8.246c1.009 5.26 5.63 8.929 10.799 8.929.684 0 1.38-.064 2.076-.197 5.956-1.144 9.873-6.92 8.73-12.876-1.141-5.956-6.91-9.868-12.875-8.732"
            fill={colors.darkTomato}
          />
          <path
            d="M19.281 27.044a1.372 1.372 0 1 0-2.685.562 1.372 1.372 0 0 0 2.685-.562M25.288 27.164a1.372 1.372 0 1 0-.561-2.685 1.372 1.372 0 0 0 .561 2.685M22.927 33.828c-.484.1-.979.01-1.395-.264a1.838 1.838 0 0 1-.8-1.172 1.859 1.859 0 0 1 1.436-2.197c1-.204 1.988.438 2.196 1.438a1.858 1.858 0 0 1-1.437 2.195m-1.168-5.59a3.861 3.861 0 0 0-2.984 4.563c.21 1.008.8 1.874 1.663 2.438a3.827 3.827 0 0 0 2.9.547 3.861 3.861 0 0 0 2.984-4.563 3.857 3.857 0 0 0-4.563-2.985"
            fill={colors.darkTomato}
          />
          <g transform="translate(0 -.367)">
            <mask id="b" fill={colors.white}>
              <use xlinkHref="#a" />
            </mask>
            <path
              d="M41.37 49.982a2.51 2.51 0 0 1-1.625 1.053h-.002l-26.71 4.922a2.511 2.511 0 0 1-1.894-.406 2.515 2.515 0 0 1-1.054-1.627L2.042 10.28A2.535 2.535 0 0 1 4.075 7.33l26.711-4.92a2.535 2.535 0 0 1 2.949 2.031l8.041 43.645a2.519 2.519 0 0 1-.404 1.896m2.371-2.259l-8.04-43.646a4.539 4.539 0 0 0-5.28-3.635L3.712 5.363a4.538 4.538 0 0 0-3.637 5.28l8.043 43.645a4.506 4.506 0 0 0 1.886 2.911 4.506 4.506 0 0 0 3.392.724l26.71-4.92a4.497 4.497 0 0 0 2.911-1.887 4.498 4.498 0 0 0 .725-3.393"
              fill={colors.darkTomato}
              mask="url(#b)"
            />
          </g>
          <g transform="translate(14 3.633)">
            <mask id="d" fill={colors.white}>
              <use xlinkHref="#c" />
            </mask>
            <path
              d="M35.742 47.724l-8.04-43.646c-.368-1.99-2-3.412-3.904-3.653l.395 2.136c.772.316 1.38.999 1.541 1.88l8.041 43.646a2.52 2.52 0 0 1-.404 1.896c-.384.556-.962.93-1.628 1.053l-26.71 4.92a2.512 2.512 0 0 1-1.895-.404c-.1-.07-.188-.152-.276-.233l-2.2.406A4.491 4.491 0 0 0 2.002 57.2a4.505 4.505 0 0 0 3.393.724l26.71-4.922a4.492 4.492 0 0 0 2.911-1.885 4.5 4.5 0 0 0 .725-3.393"
              fill={colors.darkTomato}
              mask="url(#d)"
            />
          </g>
          <g opacity={0.4} fill={colors.darkTomato}>
            <path d="M40.066 21.884a16.256 16.256 0 0 1 1.571 2.035l-.21-1.15A16.842 16.842 0 0 0 39.902 21l.163.885zM44.118 37.383l-.215-1.168c-.18.846-.433 1.67-.742 2.47l.166.897c.312-.71.577-1.446.791-2.199M39.262 17.517c.485.36.957.74 1.407 1.143l-.148-.807a20.145 20.145 0 0 0-1.393-1.062l.134.726zM44.102 43.79c.327-.483.639-.977.922-1.487l-.15-.812c-.275.539-.582 1.06-.906 1.569l.134.73zM38.584 13.84c.455.256.91.515 1.345.803l-.126-.684a22.915 22.915 0 0 0-1.337-.763l.118.643zM44.726 47.176c.014.075.008.149.019.224.342-.395.682-.791.996-1.21l-.124-.673c-.304.424-.637.826-.971 1.227l.08.432zM46.268 49.057c-.823.933-1.74 1.782-2.7 2.583a24.345 24.345 0 0 1-2.279 1.691c-.946.62-1.922 1.2-2.96 1.687l1.708-.315a24.971 24.971 0 0 0 6.345-5.025l-.114-.621zM39.16 10.471c-.426-.215-.866-.404-1.305-.594l.108.586c.444.201.883.41 1.313.636l-.116-.628zM21.715 57.041c.874.224 1.757.42 2.66.548l1.695-.312a24.346 24.346 0 0 1-3.157-.457l-1.198.221z" />
          </g>
        </g>
      </svg>
    );
  }
}
