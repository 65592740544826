import React from 'react';
import styled from "styled-components";
import { PageSeparator } from "../MobileApp";
import { colors } from "../vars/globalVars";

const Hero = styled.div`
  color: ${colors.skyBlue};
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.skyBlue};
  margin: 0 20px;
  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

const Sidekick = styled.p`
  color: ${colors.skyBlue};
  font-family: "Belwe";
  font-size: 15px;
  font-weight: 500;
  padding-top: 30px;
  margin: 0 20px;
`;

const TheGame = ({ setHeaderColor }) => (
  <div className="mobile-content">

    <Hero>
      <p>Closer is a game, yet it's anything but a competition.</p>
      <p>The only thing you can win is time well spent together,
        new friends, honest stories and occasional giggles.</p>
    </Hero>
    <Sidekick>There are two types of playing cards in the game.</Sidekick>

    <PageSeparator
      position={{position: 'absolute', bottom: 50}}
      onEnterViewport={() => setHeaderColor(colors.skyBlue)}
    />
  </div>
);

export default TheGame;
