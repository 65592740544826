import React from "react";
import styled, { css } from "styled-components";

import { colors } from "../vars/globalVars";

import hillImg from "../assets/img/08 Website_Cards_Display.png";
import card75 from "../assets/video/75.mp4";
import card75Poster from "../assets/img/card75.png";

const Container = styled.section`
  width: 100%;
  height: 369px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  position: absolute;
  top: 55%;

  @media (min-height: 841px) {
    height: 461px;
  }
`;

const CardList = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  opacity: 0;
  position: absolute;

  img,
  video {
    width: 150px;
    backface-visibility: hidden;
    border: none;
    border-radius: 5px;
    box-shadow: 10px 10px 0px rgba(237, 31, 86, 0.15);

    @media (min-height: 640px) {
      width: 221px;
    }
    
    @media (min-height: 841px) {
      width: 276px;
    }

    ${props =>
      props.blueShadow && css`
        box-shadow: 10px 10px 0px rgba(30, 81, 163, 0.15);
      `
    };
  }
`;

const CardTitle = styled.h1`
  display: none;
  color: ${props => props.color || colors.white};
  font-size: 34px;
  text-align: center;
  margin: 32px 0 0;
  opacity: 0;
`;

const CardDescription = styled.h3`
  display: none;
  color: ${props => props.color || colors.white};
  font-family: "Belwe";
  font-size: 15px;
  text-align: center;
  margin: 24px 0 0;
  opacity: 0;
`;

export default class GameDetailCards extends React.Component {
  render() {
    return (
      <Container id="GameDetailCards">
        <CardList id="GDCards">
          <Card id="GDCard1" blueShadow>
            <img src={hillImg} alt="Climbing" />
            <CardTitle id="GDCard1Title" color={colors.skyBlue}>
              Question Cards
            </CardTitle>
            <CardDescription id="GDCard1Description" color={colors.skyBlue}>
              The player who has a Question Card chooses another player to answer it.
            </CardDescription>
          </Card>
          <Card id="GDCard2">
            <video loop muted poster={card75Poster}>
              <source src={card75} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <CardTitle id="GDCard2Title" color={colors.pink}>
              Action Cards
            </CardTitle>
            <CardDescription id="GDCard2Description" color={colors.pink}>
              The player who has an Action Card chooses another player to perform it.
            </CardDescription>
          </Card>
        </CardList>
      </Container>
    );
  }
}
