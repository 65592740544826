import React from "react";
import styled from "styled-components";

import {climbingImages} from '../tweens/t7_stairs';

const Container = styled.section`
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  left: 0;
`;

const Img = styled.img`
  position: absolute;
  height: 100%;
  display: none;
  opacity: 0;
`;

export default class ClimbingStairs extends React.Component {
  render() {
    return (
      <Container id="ClimbingStairs">
        {climbingImages.map((image, index) => (
          <Img
            key={`ClimbingStairsImg${index}`}
            alt={`Climbing stairs step ${index}`}
            id={`ClimbingStairsImg${index}`}
            className="ClimbingStairsImg"
            src={image}
          />
        )) }
      </Container>
    );
  }
}
