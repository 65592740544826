import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

const Svg = styled.svg`
  width: 100%;
  max-width: 789px;
  enable-background: new 0 0 1580 1280;
  height: 100%;
  max-height: 519px;

	.st0{
    fill: ${colors.shadow};
  }
	.st1{
    fill:none;
    stroke: ${colors.darkTeal};
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

export default class HowToPlayImg3 extends React.Component {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        viewBox="0 0 1580 1280"
        xmlSpace="preserve" >
        <path
          className="st0"
          d="M1130.5,792.2l2.3-0.5c6.2,36-2.7,57.4-32.6,78.3l-192.7,134.5c-18.8,13.1-41.3,20.1-64.2,18.8 c-15.9-0.9-32.5-4.3-41.1-13.4c-16.9-18-271.8-289.6-340.5-362.8c-10.4-11.1-13.5-27.2-7.9-41.3l0,0l339,359.5 c22.2,23.4,67.7,26.9,98.1,0.8L1130.5,792.2z"
        />
        <path
          className="st1"
          d="M1116,715.4L875.8,888.2c-25.6,18.4-61.9,17.2-80.6-2.7L427,495c-18.8-19.9-13.2-51.2,12.4-69.7l240.1-172.7 c25.6-18.4,61.9-17.2,80.6,2.7l368.2,390.4C1147.1,665.6,1141.5,697,1116,715.4"
        />
        <path
          className="st1"
          d="M429.1,497.3c0,0-17.6,25,5.8,47.1l351.6,373.1c26.1,25.7,62.6,31.5,99.3,5.9l230.8-166.1 c19.4-14,26.4-41.6,18-63.6"
        />
        <path
          className="st1"
          d="M1137.4,725.1c0,0,29.6,41.2-17.8,76.8l-230.8,166c-36.7,25.5-73.2,19.8-99.3-5.9l-351.7-373 c-19.3-21.1-5.9-45.6-5.9-45.6"
        />
        <line className="st1" x1="509.9" y1="477.5" x2="670.2" y2="362.1" />
        <line className="st1" x1="551.5" y1="524.6" x2="711.8" y2="409.2" />
        <line className="st1" x1="593.1" y1="571.7" x2="753.5" y2="456.3" />
      </Svg>
    );
  }
}
