import React from "react";

import { colors } from "../vars/globalVars";

export default class MCEncouraging extends React.Component {
  render() {
    return (
      <svg width={50} height={62} xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <path id="a" d="M0 .367h43.819V58H0z" />
          <path id="c" d="M.662.425h35.157V58H.662z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M26.745 27.099a2.034 2.034 0 0 1-1.32.842 2.056 2.056 0 0 1-1.532-.34 2.031 2.031 0 0 1-.84-1.321 2.036 2.036 0 0 1 .338-1.531 2.033 2.033 0 0 1 1.683-.873 2.04 2.04 0 0 1 2.012 1.693 2.033 2.033 0 0 1-.34 1.53m2.78 6.547l-1.456-8.251a3.025 3.025 0 0 0-1.253-1.968 3.017 3.017 0 0 0-2.277-.504 3.05 3.05 0 0 0-2.473 3.53c.142.802.587 1.5 1.253 1.967a3.018 3.018 0 0 0 2.279.505 3.022 3.022 0 0 0 1.875-1.149l1.232 6.979a8.988 8.988 0 0 1-5.377 2.98c-4.91.782-9.555-2.58-10.338-7.499-.784-4.918 2.58-9.556 7.498-10.338a8.96 8.96 0 0 1 6.723 1.607 8.972 8.972 0 0 1 3.616 5.891 8.987 8.987 0 0 1-1.302 6.25m-1.177-13.709a10.893 10.893 0 0 0-8.165-1.953c-5.972.951-10.058 6.584-9.108 12.556.86 5.392 5.532 9.246 10.828 9.246.57 0 1.15-.045 1.73-.137 5.973-.951 10.059-6.584 9.108-12.558a10.901 10.901 0 0 0-4.392-7.154"
            fill={colors.darkTomato}
          />
          <path
            d="M18.19 28.53a1.373 1.373 0 1 0-.478-2.703 1.373 1.373 0 0 0 .478 2.703M24.83 24.573a1.373 1.373 0 1 0 .478 2.703 1.373 1.373 0 0 0-.478-2.703M17.454 32.045s1.005 2.473 3.593 2.016c.83-.146 1.34-.502 1.622-.933.415.308 1.014.468 1.846.322 2.587-.456 2.686-3.124 2.686-3.124-1.98 2.001-2.33-.838-4.384-.149a1.127 1.127 0 0 0-.582.48 1.137 1.137 0 0 0-.712-.252c-2.165.055-1.524 2.843-4.069 1.64"
            fill={colors.darkTomato}
          />
          <g transform="translate(0 -.367)">
            <mask id="b" fill={colors.white}>
              <use xlinkHref="#a" />
            </mask>
            <path
              d="M41.37 49.982a2.51 2.51 0 0 1-1.625 1.053h-.002l-26.71 4.922a2.511 2.511 0 0 1-1.894-.406 2.515 2.515 0 0 1-1.054-1.627L2.042 10.28A2.535 2.535 0 0 1 4.075 7.33l26.711-4.92a2.535 2.535 0 0 1 2.949 2.031l8.041 43.645a2.519 2.519 0 0 1-.404 1.896m2.371-2.259l-8.04-43.646a4.539 4.539 0 0 0-5.28-3.635L3.712 5.363a4.538 4.538 0 0 0-3.637 5.28l8.043 43.645a4.506 4.506 0 0 0 1.886 2.911 4.506 4.506 0 0 0 3.392.724l26.71-4.92a4.497 4.497 0 0 0 2.911-1.887 4.498 4.498 0 0 0 .725-3.393"
              fill={colors.darkTomato}
              mask="url(#b)"
            />
          </g>
          <g transform="translate(14 3.633)">
            <mask id="d" fill={colors.white}>
              <use xlinkHref="#c" />
            </mask>
            <path
              d="M35.743 47.724L27.702 4.078c-.367-1.99-2-3.412-3.903-3.653l.394 2.136c.773.316 1.38.999 1.542 1.88l8.04 43.646a2.52 2.52 0 0 1-.404 1.896c-.383.556-.961.93-1.627 1.053l-26.711 4.92a2.512 2.512 0 0 1-1.894-.404c-.101-.07-.188-.152-.277-.233l-2.2.406A4.491 4.491 0 0 0 2.004 57.2a4.505 4.505 0 0 0 3.392.724l26.71-4.922a4.492 4.492 0 0 0 2.912-1.885 4.5 4.5 0 0 0 .725-3.393"
              fill={colors.darkTomato}
              mask="url(#d)"
            />
          </g>
          <g opacity={0.4} fill={colors.darkTomato}>
            <path d="M40.067 21.884a16.256 16.256 0 0 1 1.57 2.035l-.21-1.15A16.842 16.842 0 0 0 39.904 21l.163.885zM44.118 37.383l-.215-1.168c-.18.846-.433 1.67-.742 2.47l.166.897c.312-.71.577-1.446.791-2.199M39.262 17.517c.485.36.957.74 1.407 1.143l-.148-.807a20.145 20.145 0 0 0-1.393-1.062l.134.726zM44.102 43.79c.327-.483.64-.977.922-1.487l-.149-.812c-.276.539-.583 1.06-.907 1.569l.134.73zM38.584 13.84c.455.256.91.515 1.345.803l-.126-.684a22.915 22.915 0 0 0-1.337-.763l.118.643zM44.726 47.176c.014.075.008.149.02.224.341-.395.681-.791.995-1.21l-.124-.673c-.304.424-.637.826-.97 1.227l.08.432zM46.269 49.057c-.823.933-1.74 1.782-2.7 2.583a24.345 24.345 0 0 1-2.28 1.691c-.945.62-1.921 1.2-2.96 1.687l1.709-.315a24.971 24.971 0 0 0 6.345-5.025l-.114-.621zM39.16 10.471c-.426-.215-.866-.404-1.305-.594l.108.586c.444.201.883.41 1.314.636l-.116-.628zM21.715 57.041c.874.224 1.757.42 2.66.548l1.695-.312a24.346 24.346 0 0 1-3.157-.457l-1.198.221z" />
          </g>
        </g>
      </svg>
    );
  }
}
