import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

const Svg = styled.svg`
  width: 100%;
  max-width: 789px;
  height: 100%;
  max-height: 519px;

	.st0{
    fill: ${colors.shadow};
  }
	.st1{
    fill: none;
    stroke: ${colors.darkTeal};
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

export default class HowToPlayImg5 extends React.Component {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        viewBox="0 0 1580 1280"
        xmlSpace="preserve" >
        <ellipse
          className="st0"
          cx="825.4" cy="970.3" rx="254.5" ry="107.4"
        />
        <path
          className="st1"
          d="M1002.1,311l-0.2,257.5c1.6,123.7-95.3,225.6-218.9,227.2s-227.3-97.7-228.9-221.4L552.9,311"
        />
        <path
          className="st1"
          d="M560.6,624.2c-75.2,14.3-135.6-63-134.2-135.9l0.4-102.6c0-4,0.9-8,2.6-11.7c4.7-9.7,15.9-14.3,26-10.7 l97.5,33.6"
        />
        <path
          className="st1"
          d="M1002.6,596c72.9,19.6,121.7-64.5,120.6-137.5l-0.2-114.8c0.1-10.9-6.2-18.7-15-19.5c-2.5-0.1-4.9,0.3-7.2,1.2 l-98.2,37.4"
        />
        <polygon
          className="st1"
          points="856.3,544.9 824.6,479.1 801.3,550.9 736.5,544.9 786.7,594.7 777,656 831.6,619.4 881.7,647.6  870.5,586.5 914.4,530.4 "
        />
        <line
          className="st1"
          x1="783" y1="795.7" x2="783" y2="930.2"
        />
        <ellipse
          className="st1"
          cx="777.5" cy="311" rx="224.6" ry="77"
        />
        <ellipse
          className="st1"
          cx="789.2" cy="951.5" rx="135.9" ry="56.2"
        />
      </Svg>
    );
  }
}
