import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

import MCInspiring from "../assets/MCInspiring";
import MCEmotional from "../assets/MCEmotional";
import MCFunny from "../assets/MCFunny";
import MCHeartwarming from "../assets/MCHeartwarming";
import MCSurprising from "../assets/MCSurprising";
import MCEncouraging from "../assets/MCEncouraging";

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  position: absolute;

  @media (min-width: 768px) {
    width: 90%;
    flex-direction: row;
  justify-content: space-around;
  }

  @media (min-width: 1201px) {
    width: 85%;
  }
  
  @media (max-height: 640px) {
    margin-top: 20px;
  }
`;

const CardTypesText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;

  @media (min-width: 768px) {
    height: 100%;
    width: 30%;
    padding: 0;
  }
`;

const Hero = styled.p`
  color: ${colors.darkTomato};
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.darkTomato};
  margin: 0;
  
  @media (max-height: 640px) {
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 10px;
  }

  @media (min-width: 768px) {
    font-size: 34px;
  }
`;

const Sidekick = styled.p`
  color: ${colors.darkTomato};
  font-family: "Belwe";
  font-size: 15px;
  font-weight: 500;
  padding-top: 30px;
  margin: 0;
  
  @media (max-height: 640px) {
    padding-top: 10px;
  }
`;

const CardTypesCards = styled.div`
  width: 100%;
  color: ${colors.darkTomato};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20px;
  margin: 0;

  @media (min-width: 768px) {
    width: 46%;
    padding: 0;
  }
`;

const CardTypesCardsRow = styled.div`
  display: flex;

  &:first-child {
    margin-bottom: 45px;
  }

  @media (min-width: 1201px) {
    &:first-child {
      margin-bottom: 60px;
    }
  }
`;

const CardType = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 130px;

  svg {
    margin-bottom: 10px;
  }

  @media (min-width: 1201px) {
    width: 150px;
  }
`;

export default class CardTypes extends React.Component {
  render() {
    return (
      <Container id="CardTypes">
        <CardTypesText id="CardTypesText">
          <Hero>
            In addition, the set contains winning cards.
          </Hero>
          <Sidekick>
            When the game ends, players will use the Winning Cards to
            deliberate which story was the most:
          </Sidekick>
        </CardTypesText>
        <CardTypesCards id="CardTypesCards">
          <CardTypesCardsRow>
            <CardType className="CardType">
              <MCInspiring />
              Inspiring
            </CardType>
            <CardType className="CardType">
              <MCEmotional />
              Emotional
            </CardType>
            <CardType className="CardType">
              <MCFunny />
              Funny
            </CardType>
          </CardTypesCardsRow>
          <CardTypesCardsRow>
            <CardType className="CardType">
              <MCHeartwarming />
              Heartwarming
            </CardType>
            <CardType className="CardType">
              <MCSurprising />
              Surprising
            </CardType>
            <CardType className="CardType">
              <MCEncouraging />
              Encouraging
            </CardType>
          </CardTypesCardsRow>
        </CardTypesCards>
      </Container>
    );
  }
}
