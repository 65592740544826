import React from "react";
import { TimelineMax, TweenMax, Power2 } from "gsap";
import styled, { css } from "styled-components";

import { colors } from "../vars/globalVars";

import hillImg from "../assets/img/08 Website_Cards_Display.png";
import birdImg from "../assets/img/ACBird.png";
import pinocchioImg from "../assets/img/ACPinocchio.png";
import dude1Img from "../assets/img/ACDude1.png";
import dude2Img from "../assets/img/ACDude2.png";

import card12 from "../assets/video/12.mp4";
import card12Poster from "../assets/img/card12.png";
import card18 from "../assets/video/18.mp4";
import card18Poster from "../assets/img/card18.png";
import card19 from "../assets/video/19.mp4";
import card19Poster from "../assets/img/card19.png";
import card49 from "../assets/video/49.mp4";
import card49Poster from "../assets/img/card49.png";
import card57 from "../assets/video/57.mp4";
import card57Poster from "../assets/img/card57.png";
import card75 from "../assets/video/75.mp4";
import card75Poster from "../assets/img/card75.png";
import card76 from "../assets/video/76.mp4";
import card76Poster from "../assets/img/card76.png";

import card86 from "../assets/video/86.mp4";
import card86Poster from "../assets/img/card86.png";

import CloserLogo from "../assets/CloserLogo";
import HoverCursor from "../assets/img/HoverCursor.png";

const Container = styled.section`
  height: 100%;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;

  @media (min-width: 768px) {
    width: 90%;
    top: 0;
  }

  @media (min-width: 1201px) {
    width: 85%;
  }
`;

const CardList = styled.div`
  height: 100%;
  min-width: 100%;
  display: none;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &#CardsGallery .Card {
    display: none;
    height: 369px;
    position: relative;
    opacity: 0;

    @media (min-height: 841px) {
      height: 461px;
    }

    img,
    video {
      position: absolute;
      top: 0;
    }

    .CardBack {
      background-color: ${colors.white};
      backface-visibility: hidden;
      border-radius: 5px;
      box-shadow: 10px 10px 0px rgba(237, 31, 86, 0.15);
      position: absolute;
      top: 0;

      ${props =>
        props.blueShadow && css`
          box-shadow: 10px 10px 0px rgba(30, 81, 163, 0.15);
        `
      };
    }
  }

  &#FamilyCards {
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    > div {
      display: flex;
      flex: 1;
      align-items: flex-start;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: flex-start;
      }

      &:first-child {
        align-items: flex-end;

        @media (min-width: 768px) {
          justify-content: flex-end;
        }
      }

      @media (min-width: 768px) {
        justify-content: flex-start;
      }
    }

    .Card {
      margin: 20px;
      max-width: 25%;
      
      @media (min-height: 640px) {
        max-width: 30%;
      }
      
      @media (min-height: 800px) {
        max-width: 40%;
      }

      @media (min-width: 768px) {
        margin: 0;
        max-width: none;

        &:not(.first) {
          margin-left: 40px;
        }
      }
    }
  }
`;

const Card = styled.div`
  width: 221px;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;

  @media (min-height: 841px) {
    width: 276px;
  }

  &:hover {
    &.i > 0 && cursorOn {
      cursor: url(${HoverCursor}), auto;
    }
  }

  img,
  video {
    display: block;
    max-width: 100%;
    backface-visibility: hidden;
    border: none;
    border-radius: 5px;
    box-shadow: 10px 10px 0px rgba(237, 31, 86, 0.15);

    ${props =>
      props.blueShadow && css`
        box-shadow: 10px 10px 0px rgba(30, 81, 163, 0.15);
      `
    };
  }
`;

const CardTitle = styled.h1`
  position: absolute;
  margin: 0;
  top: -17%;
  left: -25%;
  text-align: center;
  width: 150%;
  color: ${props => props.color || colors.white};
  font-size: 34px;

  @media (min-height: 841px) {
    width: 100%;
    top: -16%;
    left: auto;
  }
`;

const CardDescription = styled.h3`
  position: absolute;
  text-align: center;
  margin: 0;
  bottom: -23%;
  left: -15%; // because of width 150%
  width: 130%;
  font-family: "Belwe";
  font-size: 15px;
  color: ${props => props.color || colors.white};

  @media (min-height: 841px) {
    bottom: -17%;
  }
`;

const CardBack = styled.div`
  width: 100%;
  height: 369px;

  @media (min-height: 841px) {
    height: 461px;
  }
`;

const CardBackContent = styled.div`
  padding: 30.8px 23.1px 23.1px;

  @media (min-height: 841px) {
    padding: 44px 33px 33px;
  }
`;
const CardBackTitle = styled.div`
  display: inline-block;
  font-family: "Belwe";
  font-weight: 500;
  font-size: 13px;
  color: ${colors.darkGrey};
  padding: 0 6px 6px 0;
  border-bottom: 1px solid ${colors.darkGrey};
  margin-bottom: 15.4px;

  @media (min-height: 841px) {
    margin-bottom: 22px;
  }
`;

const CardBackQuestion = styled.div`
  color: ${colors.darkGrey};
  font-size: 18.2px;
  font-weight: 500;
  line-height: 21px;

  @media (min-height: 841px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

const CardBackCloserLogo = styled.div`
  position: absolute;
  bottom: 22.4px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);

  @media (min-height: 841px) {
    bottom: 32px;
  }
`;

const BottomProgressContainer = styled.div`
  width: 30%;
  height: 1px;
  background-color: rgba(237, 31, 86, 0.5); // colors.pink
  transform: translateX(-50%);
  position: absolute;
  bottom: 20%;
  left: 50%;
`;

const ProgressBar = styled.div`
  width: 10%;
  height: 100%;
  background-color: ${colors.pink};

  @media (min-width: 768px) {
    width: 30%;
  }
`;

const cardVideos = [
  {
    image: hillImg,
    extra: {
      typeText: "Question Cards",
      color: colors.skyBlue,
      descriptionText: "The player who has a Question Card chooses another player to answer it."
    },
    backTitleText: "Question card",
    backQuestionText: "What's the craziest thing you've done for love?",
  },
  {
    video: card75,
    poster: card75Poster,
    extra: {
      typeText: "Action Cards",
      color: colors.pink,
      descriptionText: "The player who has an Action Card chooses another player to perform it."
    },
    backTitleText: "Question card",
    backQuestionText: "What are your insecurities and why?",
  },
  {
    video: card12,
    poster: card12Poster,
    backTitleText: "Action card",
    backQuestionText: "Tell someone you love them without using the word 'love'.",
  },
  {
    video: card18,
    poster: card18Poster,
    backTitleText: "Question card",
    backQuestionText: "What would you like to learn or try?",
  },
  {
    video: card19,
    poster: card19Poster,
    backTitleText: "Question card",
    backQuestionText: "How full is your mind? How about your heart?",
  },
  {
    video: card49,
    poster: card49Poster,
    backTitleText: "Question card",
    backQuestionText: "Name one thing you'd risk prison for.",
  },
  {
    video: card57,
    poster: card57Poster,
    backTitleText: "Question card",
    backQuestionText: "Who would you reconcile with?",
  },
  {
    video: card76,
    poster: card76Poster,
    backTitleText: "Action card",
    backQuestionText: "Call a friend you've not talked to in a long time and tell them something meaningful.",
  },
  {
    video: card86,
    poster: card86Poster,
    backTitleText: "Question card",
    backQuestionText: "Tell us about someone you've loved but never told. "
  }
]

const timelines = [
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true }),
  new TimelineMax({ paused: true })
];

export default class Cards extends React.Component {
  componentDidMount() {
    TweenMax.set(document.querySelectorAll(".CardBack"), { rotationY: -180 });

    timelines.forEach((tl, i) => {
      tl
        .to(`#Card${i + 1} img, #Card${i + 1} video`, 0.7, {
          rotationY: 180,
          ease: Power2.easeOut
        })
        .to(
          `#Card${i + 1} .CardBack`,
          0.7,
          {
            rotationY: 0,
            force3D: false,
            ease: Power2.easeOut
          },
          "-=0.7"
        );
    });
  }

  play = tl => {
    tl.play();
  };

  reverse = tl => {
    tl.reverse();
  };

  render() {
    return (
      <Container id="Cards">
        <CardList id="CardsGallery">
          {cardVideos.map((card, i) => {
            const actualI = i + 1;
            return (
              <Card
                id={`Card${actualI}`}
                key={`Card${actualI}`}
                blueShadow={i < 1}
                className={`Card`}
                onMouseEnter={e => {
                  if (e.currentTarget.classList.contains('cursorOn')) {
                    return this.play(timelines[i]);
                  }
                }}
                onMouseLeave={e => {
                  if (e.currentTarget.classList.contains('cursorOn')) {
                    return this.reverse(timelines[i]);
                  }
                }}
                onTouchStart={e => {
                  e.preventDefault();
                  if (e.currentTarget.classList.contains('cursorOn')) {
                    return this.play(timelines[i]);
                  }
                }}
                onTouchEnd={e => {
                  e.preventDefault();
                  if (e.currentTarget.classList.contains('cursorOn')) {
                    return this.reverse(timelines[i]);
                  }
                }}>
                {card.extra &&
                  <CardTitle id={`Card${actualI}Title`} color={card.extra.color}>
                    {card.extra.typeText}
                  </CardTitle>
                }
                {card.image &&
                  <img src={card.image} alt={card.backQuestionText} />
                }
                {card.video &&
                  <video loop muted preload="auto" poster={card.poster || undefined}>
                    <source src={card.video} type="video/mp4" />
                    {card.poster &&
                      <img src={card.poster} alt={card.backQuestionText} />
                    }
                  </video>
                }
                <CardBack className="CardBack">
                  <CardBackContent>
                    <CardBackTitle>{card.backTitleText}</CardBackTitle>
                    <CardBackQuestion>
                      {card.backQuestionText}
                    </CardBackQuestion>
                    <CardBackCloserLogo>
                      <CloserLogo color={colors.darkGrey} isCardBack xsmall />
                    </CardBackCloserLogo>
                  </CardBackContent>
                </CardBack>
                {card.extra &&
                  <CardDescription id={`Card${actualI}Description`} color={card.extra.color}>
                    {card.extra.descriptionText}
                  </CardDescription>
                }
              </Card>
            )
          })}
        </CardList>
        <CardList id="FamilyCards">
          <div>
            <Card id="Card11" className="Card first">
              <img src={birdImg} alt="" />
            </Card>
            <Card id="Card12" className="Card">
              <img src={pinocchioImg} alt="" />
            </Card>
          </div>
          <div>
            <Card id="Card13" className="Card">
              <img src={dude1Img} alt="" />
            </Card>
            <Card id="Card14" className="Card">
              <img src={dude2Img} alt="" />
            </Card>
          </div>
        </CardList>
        <BottomProgressContainer id="ProgressBar">
          <ProgressBar />
        </BottomProgressContainer>
      </Container>
    );
  }
}
