import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

const Svg = styled.svg`
  width: 100%;
  max-width: 789px;
  height: 100%;
  max-height: 519px;

	.st0{
    fill: none;
    stroke: ${colors.darkTeal};
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
	.st1{
    fill: ${colors.shadow};
  }
`;

export default class HowToPlayImg2 extends React.Component {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        viewBox="0 0 1580 1280"
        xmlSpace="preserve" >
        <path
          className="st0"
          d="M1166.2,216.9c-84,0-152,63.8-152,142.5c0,21.7,5.3,43.2,15.3,62.4l-38.3,64.8l68.7-25.3 c0,0,47.8,40.7,106.3,40.7c84,0,152-63.8,152-142.5S1250.2,216.9,1166.2,216.9z"
        />
        <path
          className="st0"
          d="M1143.6,327.7c0-14.3,11.6-25.9,25.8-25.9c14.2,0.2,25.7,11.7,25.9,25.9c0,8.5-4.2,16.5-11.3,21.3 c-9.2,6.4-14.7,17-14.6,28.3l0,0"
        />
        <polyline
          className="st0"
          points="1169.4,408.7 1169.4,408.7 1169.4,418.1 "
        />
        <path
          className="st0"
          d="M913.1,843c0,0,10.7,36.1,9,46.5"
        />
        <path
          className="st1"
          d="M827.4,772.9c0,0-15.3,20.5-2.6,29.3s24.1,32.1,24.1,32.1s8.6,13.7,8.4,13s15.5-35,15.5-35L827.4,772.9z"
        />
        <polygon
          className="st1"
          points="988.5,804.3 985.2,784.8 982.7,780.9 957.1,809.2 962,818 "
        />
        <path
          className="st0"
          d="M827.4,774.8c-7,1.3-11.5,14.6-5.7,25.1"
        />
        <path
          className="st0"
          d="M834.2,870.8l-25-46.3c0,0-8.1-17.3,4.2-23.6c11-5.6,24.5,14.6,24.5,14.6l16,27.6"
        />
        <line className="st0" x1="986.1" y1="608" x2="1010.8" y2="628.7" />
        <line className="st0" x1="971.6" y1="623" x2="1027.8" y2="669.5" />
        <line className="st0" x1="957.1" y1="638" x2="1013.3" y2="684.5" />
        <line className="st0" x1="942.6" y1="653" x2="998.8" y2="699.5" />
        <path
          className="st0"
          d="M860.8,803.9l13.1,11.4L820.7,769c-5.8-5.1-6.4-13.9-1.4-19.8c0.1-0.1,0.2-0.3,0.4-0.4l163.7-173.4 c4.9-5.2,13-5.7,18.5-1.2l87.3,71.1c5.8,4.7,6.7,13.3,2,19.2c-0.2,0.2-0.3,0.4-0.5,0.6L928.8,842.3c-4.1,4.5-11.1,4.9-15.6,0.8l0,0"
        />
        <path
          className="st0"
          d="M719.9,1068l25.7-46.7c7.2-13.3,11.8-27.8,13.5-42.8c2.5-21.9,9.9-55.5,31.8-72.1l35.3-28.8 c15.5-12.6,28.5-28.1,38.2-45.5l30.2-53.8c0,0,8.7-11.3,23-1.3c10.8,7.5,2.7,45.9-2.8,58.7S895,890,895,890h22.7 c5.9,0,11.6-2.2,15.9-6.2l18.8-17.5l7.3-34.5l-2.6-17.8"
        />
        <path
          className="st0"
          d="M985.2,784.8l15.5,38.8c1,2.3,1.4,4.8,1.3,7.3l-4.8,80.5c-0.6,10.4-4.9,20.3-12.1,27.8l-75.6,78.8l-50,44 c-8.1,8.4-24.6,24.4-29.1,35.2"
        />
        <path
          className="st0"
          d="M869.1,480.5c0,0,122.7-63.2,37.8-178.1c0,0-56-93.2-187.8-88.1s-278,157.5-344.4,186.9s-75.9,68.3-39.6,123.8 S415,682.1,510,705.6"
        />
        <path
          className="st0"
          d="M540,709.7c0,0,75.7,17,142.3-78.1"
        />
        <path
          className="st0"
          d="M625.4,647.9c0,0,52-1.3,104.6-52c30-28.9,62.8-70.7,85.4-101.3c20-27.1,44.2-56.3,71.9-68.7l18.9-12.6"
        />
        <path
          className="st0"
          d="M579.8,853.4l15.8-23.1c8-11.8,20.8-19.4,34.9-20.8c22-2,55.8-7.9,76.7-24.5c31.2-24.7,137.8-181.9,159.2-292 c3-15.6,7.2-31,12.7-46l8.2-21.2"
        />
      </Svg>
    );
  }
}
