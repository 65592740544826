import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

const Container = styled.section`
  width: 100%;
  height: 60%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0;
  position: absolute;
  left: 0;
  top: 50px;

  @media (min-width: 768px) {
    width: 45%;
    height: 100%;
    padding-right: 137px;
    padding-left: 50px;
    margin-left: 5%;
  }

  @media (min-width: 768px) and (min-height: 840px) {
    padding-right: 178px;
  }

  @media (min-width: 1201px) {
    width: 42.5%;
    margin-left: 7.5%;
  }
`;

const Hero = styled.div`
  color: ${colors.skyBlue};
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.skyBlue};
  margin: 0 20px;

  @media (min-width: 768px) {
    font-size: 34px;
    line-height: 34px;
    margin: 0;
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

const Sidekick = styled.p`
  color: ${colors.skyBlue};
  font-family: "Belwe";
  font-size: 15px;
  font-weight: 500;
  padding-top: 30px;
  margin: 0 20px;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export default class GameDetails extends React.Component {
  render() {
    return (
      <Container id="GameDetails">
        <Hero>
          <p>Closer is a game, yet it's anything but a competition.</p>
          <p>The only thing you can win is time well spent together,
            new friends, honest stories and occasional giggles.</p>
        </Hero>
        <Sidekick>There are two types of playing cards in the game.</Sidekick>
      </Container>
    );
  }
}
