import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

import UpArrow from "../assets/UpArrow";
import DownArrow from "../assets/DownArrow";
import HowToPlayImg1 from "../assets/HowToPlayImg1";
import HowToPlayImg2 from "../assets/HowToPlayImg2";
import HowToPlayImg3 from "../assets/HowToPlayImg3";
import HowToPlayImg4 from "../assets/HowToPlayImg4";
import HowToPlayImg5 from "../assets/HowToPlayImg5";

const Container = styled.section`
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 90%;
    justify-content: space-between;
    flex-direction: row;
  }

  @media (min-width: 1201px) {
    width: 85%;
  }
`;

const Sidebar = styled.div`
  height: 100%;
  width: 30%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9;
  
  @media (min-width: 768px) {
    display: flex;
  }
`;

const MobileProgress = styled.div`
  height: 15%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: none;
  }
`;

const SideLegend = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 40%;
  line-height: 45px;
  vertical-align: middle;
  padding-left: 20px;
  position: absolute;
  left: 7%;

  @media (min-width: 1201px) {
    left: 10%;
  }
`;

const BottomLegend = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  line-height: 45px;

  > div {
    display: none;
    opacity: 0;
  }
`;

const LegendItem = styled.div`
  color: rgba(57, 95, 86, 0.3);
  cursor: pointer;
`;

const SideProgressSteps = styled.div`
  height: 40px;
  transform: rotate(-90deg);
  color: ${colors.darkTeal};
  font-family: "Belwe";
  position: absolute;
  top: 22%;
  left: 7%;

  @media (min-width: 1201px) {
    left: 10%;
  }
`;

const BottomProgressSteps = styled.div`
  font-family: "Belwe";
  text-align: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

const ProgressBarEmpty = styled.div`
  height: 40%;
  width: 1px;
  background-color: rgba(57, 95, 86, 0.3); // colors.darkTeal
  position: absolute;
  left: 7%;

  @media (min-width: 1201px) {
    left: 10%;
  }
`;

const ProgressBarFull = styled.div`
  height: 0%;
  width: 100%;
  background-color: ${colors.darkTeal};
`;

const ArrowsContainer = styled.div`
  display: flex;
  height: 50px;
  justify-content: space-between;
  flex-direction: column;
  margin-left: -5px;
  position: absolute;
  bottom: 20%;
  left: 7%;

  @media (min-width: 1201px) {
    left: 10%;
  }
`;

const CurrentStep = styled.span`
  left: 7%;

  @media (min-width: 1201px) {
    left: auto;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  @media (min-width: 768px) {
    width: 70%;
    height: 100%;
    flex: 1;
    padding: 0;
  }
`;

const Hero = styled.h1`
  color: ${colors.darkTeal};
  font-size: 45px;
  font-weight: 500;

  @media (min-width: 1201px) {
    font-size: 58px;
  }
`;

const HeroImg = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: 15px;
  }
`;

export default class HowToPlay extends React.Component {
  render() {
    const { navigateTo } = this.props;
    return (
      <Container id="HowToPlay">
        <Sidebar id="HowToPlayDesktopSidebar">
          <SideProgressSteps>
            <CurrentStep className="CurrentStep">0</CurrentStep>
            <span> / 5</span>
          </SideProgressSteps>
          <ProgressBarEmpty>
            <ProgressBarFull id="SideProgressBarFull" />
          </ProgressBarEmpty>
          <ArrowsContainer>
            <UpArrow />
            <DownArrow />
          </ArrowsContainer>
          <SideLegend id="SideLegend">
            <LegendItem onClick={() => navigateTo("HowToPlayStep1")} className="HowToPlayText1">Draw 5 cards</LegendItem>
            <LegendItem onClick={() => navigateTo("HowToPlayStep2")}  className="HowToPlayText2">Start conversations</LegendItem>
            <LegendItem onClick={() => navigateTo("HowToPlayStep3")}  className="HowToPlayText3">Draw more cards </LegendItem>
            <LegendItem onClick={() => navigateTo("HowToPlayStep4")}  className="HowToPlayText4">Play continues clockwise</LegendItem>
            <LegendItem onClick={() => navigateTo("HowToPlayStep5")}  className="HowToPlayText5">Vote the winners</LegendItem>
          </SideLegend>
        </Sidebar>
        <ImageContainer>
          <Hero id="HowToPlayHero">How to play</Hero>
          <HeroImg id="HowToPlayImg1">
            <HowToPlayImg1 />
          </HeroImg>
          <HeroImg id="HowToPlayImg2">
            <HowToPlayImg2 />
          </HeroImg>
          <HeroImg id="HowToPlayImg3">
            <HowToPlayImg3 />
          </HeroImg>
          <HeroImg id="HowToPlayImg4">
            <HowToPlayImg4 />
          </HeroImg>
          <HeroImg id="HowToPlayImg5">
            <HowToPlayImg5 />
          </HeroImg>
        </ImageContainer>
        <MobileProgress id="HowToPlayMobileProgress">
          <BottomProgressSteps id="CurrentStepContainer">
            <CurrentStep className="CurrentStep">0</CurrentStep>
            <span> / 5</span>
          </BottomProgressSteps>
          <BottomLegend id="BottomLegend">
            <LegendItem className="HowToPlayText1">Draw 5 cards</LegendItem>
            <LegendItem className="HowToPlayText2">Start conversations</LegendItem>
            <LegendItem className="HowToPlayText3">Draw more cards </LegendItem>
            <LegendItem className="HowToPlayText4">Play continues clockwise</LegendItem>
            <LegendItem className="HowToPlayText5">Vote the winners</LegendItem>
          </BottomLegend>
        </MobileProgress>
      </Container>
    );
  }
}
