import React from "react";

import { colors } from "../vars/globalVars";

export default class CrownIcon2 extends React.Component {
  render() {
    return (
      <svg width={100} height={71}>
        <path
          d="M0.5 38H19.5C20.0727 78.5586 79.6667 77.4278 79.5 38H100"
          fill="none"
          stroke={colors.tomato}
          strokeOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.2895 3.79993C71.2835 1.83693 69.6905 0.241934 67.7315 0.231934C67.7225 0.231934 67.7125 0.231934 67.7025 0.231934L47.8835 0.278934C45.9105 0.283934 44.3105 1.89293 44.3145 3.86493L44.3295 10.3299L46.3285 9.90493L46.3145 3.86093C46.3115 2.99093 47.0185 2.28193 47.8885 2.27893L67.7075 2.23193H67.7205C68.5835 2.23593 69.2875 2.93993 69.2895 3.80593L69.3675 36.1909C69.3695 37.0609 68.6635 37.7709 67.7935 37.7729L61.5925 37.7879L62.0175 39.7869L67.7985 39.7729C69.7705 39.7679 71.3715 38.1589 71.3675 36.1869L71.2895 3.79993Z"
          fill={colors.tomato}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.7766 47.8368L38.5514 51.962C38.1428 52.051 37.7243 51.972 37.3743 51.7417C37.0232 51.5125 36.7823 51.1581 36.696 50.7457L30.0192 19.0359C29.8397 18.1841 30.3801 17.3442 31.224 17.163L50.4483 13.0379C50.5583 13.0149 50.6664 13.0029 50.7735 13.0029C51.4974 13.0029 52.148 13.5124 52.3036 14.2541L58.9814 45.9639C59.1609 46.8148 58.6205 47.6556 57.7766 47.8368V47.8368ZM54.2443 13.8377C54.047 12.9028 53.5006 12.1 52.7063 11.5784C51.9119 11.0589 50.9659 10.8807 50.0357 11.0789L30.8115 15.2041C29.8843 15.4043 29.089 15.9548 28.5733 16.7566C28.0567 17.5594 27.8822 18.5164 28.0785 19.4523L34.7553 51.1622C34.9527 52.0981 35.4991 52.9009 36.2944 53.4214C36.8765 53.8028 37.5409 54 38.2192 54C38.4661 54 38.716 53.974 38.9639 53.9209L58.1891 49.7958C60.103 49.3854 61.3287 47.4795 60.9221 45.5475L54.2443 13.8377Z"
          fill={colors.tomato}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8809 31.5645C42.8904 30.854 43.0681 30.2662 43.4103 29.8144C43.7629 29.3503 44.2419 29.0477 44.8341 28.9168C45.4158 28.7891 45.9965 28.8637 46.563 29.1407C47.1085 29.4075 47.5153 29.8594 47.7701 30.486L42.8809 31.5645ZM46.0858 35.1077C45.2276 35.2968 44.4948 35.1558 43.896 34.6886L42.5596 35.9307C42.9151 36.2639 43.3086 36.5215 43.742 36.6984C44.5556 37.0286 45.4576 37.0879 46.4233 36.8752C47.6208 36.6105 48.5751 35.9225 49.2604 34.8286C49.9466 33.7348 50.1567 32.459 49.8849 31.036C49.6111 29.6007 48.9496 28.5069 47.9174 27.7862C46.8861 27.0655 45.7323 26.8386 44.49 27.1125C43.3257 27.3701 42.4028 28.0346 41.746 29.0906C41.0892 30.1446 40.8649 31.3754 41.0778 32.7463C41.0778 32.7463 41.1966 33.3688 41.3021 33.6765L48.1179 32.1728C48.1351 32.8761 47.9735 33.4946 47.6304 34.0129C47.2416 34.5986 46.7227 34.9677 46.0858 35.1077Z"
          fill={colors.tomato}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.0885 39.0383C43.3631 39.7935 39.724 37.476 38.9765 33.8713C38.2279 30.2666 40.6502 26.7197 44.3756 25.9635C46.1828 25.597 48.0229 25.935 49.556 26.9146L50.5551 25.3859C48.6144 24.1464 46.2875 23.719 44.0049 24.1819C39.2866 25.1392 36.2214 29.6494 37.1733 34.2367C38.1252 38.8241 42.7388 41.7771 47.4581 40.8198C49.7408 40.3569 51.7093 39.0586 53 37.1633L51.4731 36.1472C50.4534 37.6445 48.8957 38.6718 47.0885 39.0383Z"
          fill={colors.tomato}
        />
        <g opacity="0.4">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0867 20.0039C61.0867 18.2119 59.6287 16.7539 57.8367 16.7539C57.5967 16.7539 57.3637 16.7839 57.1387 16.8339L57.2427 17.3229C57.4337 17.2799 57.6317 17.2539 57.8367 17.2539C59.3537 17.2539 60.5867 18.4869 60.5867 20.0039C60.5867 21.3329 59.6397 22.4439 58.3847 22.6989L58.4887 23.1879C59.9697 22.8849 61.0867 21.5729 61.0867 20.0039Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.754 20.0039C63.754 16.7419 61.1 14.0869 57.837 14.0869C57.407 14.0869 56.988 14.1359 56.584 14.2239L56.688 14.7129C57.059 14.6319 57.442 14.5869 57.837 14.5869C60.824 14.5869 63.254 17.0169 63.254 20.0039C63.254 22.6129 61.4 24.7969 58.94 25.3069L59.044 25.7959C61.729 25.2369 63.754 22.8529 63.754 20.0039Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.8373 11.9204C62.2953 11.9204 65.9213 15.5464 65.9213 20.0034C65.9213 23.8934 63.1603 27.1484 59.4943 27.9154L59.5983 28.4044C63.4893 27.5894 66.4213 24.1334 66.4213 20.0034C66.4213 15.2714 62.5703 11.4204 57.8373 11.4204C57.0973 11.4204 56.3833 11.5244 55.6973 11.7014C55.7873 11.8474 55.8703 11.9984 55.9463 12.1534C56.5543 12.0064 57.1853 11.9204 57.8373 11.9204Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0228 9.95891C55.2098 9.50691 56.4938 9.25391 57.8378 9.25391C62.9558 9.25391 67.2428 12.8519 68.3198 17.6509V15.9509C66.6888 11.7469 62.6108 8.75391 57.8378 8.75391C56.2858 8.75391 54.8088 9.07391 53.4648 9.64591C53.6478 9.73791 53.8298 9.83091 54.0028 9.94291C54.0108 9.94791 54.0158 9.95391 54.0228 9.95891Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.3199 24.0574V22.3574C67.4029 26.4444 64.1529 29.6524 60.0469 30.5164L60.1519 31.0064C63.8969 30.2174 66.9599 27.5614 68.3199 24.0574Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.8375 6.0874C54.4475 6.0874 51.3545 7.3254 48.9395 9.3504L49.8065 9.1664C49.8825 9.1494 49.9595 9.1424 50.0355 9.1294C52.2365 7.5414 54.9215 6.5874 57.8375 6.5874C62.0785 6.5874 65.8595 8.5704 68.3195 11.6524V10.8784C65.7665 7.9494 62.0195 6.0874 57.8375 6.0874Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.3206 29.129V28.356C66.4106 30.748 63.7006 32.464 60.6016 33.118L60.7066 33.613C63.7156 32.976 66.3686 31.369 68.3206 29.129Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.3193 32.8413V32.1763C66.3023 33.9153 63.8503 35.1563 61.1543 35.7283L61.2583 36.2173C63.8943 35.6603 66.3053 34.4893 68.3193 32.8413Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.3223 7.19141V7.86141C50.1453 5.41341 53.8163 3.92041 57.8373 3.92041C61.8423 3.92041 65.5013 5.40041 68.3193 7.83141V7.16641C65.4603 4.82741 61.8103 3.42041 57.8373 3.42041C53.8493 3.42041 50.1863 4.83641 47.3223 7.19141Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.2636 36.77C67.6236 36.567 67.9746 36.353 68.3196 36.128V35.543C67.6406 36.002 66.9196 36.401 66.1816 36.77H67.2636Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.4103 3.23779C48.0403 3.44679 47.6763 3.66679 47.3223 3.89979V4.48879C48.0113 4.02079 48.7413 3.61279 49.4923 3.23779H48.4103Z"
            fill={colors.tomato}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.2636 3.23779H66.1816C66.9196 3.60679 67.6406 4.00579 68.3196 4.46479V3.87979C67.9746 3.65479 67.6226 3.44079 67.2636 3.23779Z"
            fill={colors.tomato}
          />
        </g>
      </svg>
    );
  }
}
