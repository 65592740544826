import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

const Svg = styled.svg`
  width: 100%;
  max-width: 789px;
  height: 100%;
  max-height: 519px;

	.st0 {
    fill: ${colors.shadow};
  }
	.st1 {
    fill: none;
    stroke: ${colors.darkTeal};
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
	.st2 {
    fill: none;
  }
`;

export default class HowToPlayImg1 extends React.Component {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        viewBox="0 0 1580 1280"
        xmlSpace="preserve" >
        <path
          className="st0"
          d="M603,587.9l52,127.5c16.1,56.9,50,62.3,88.5,44.2c-27.2,34.2-96.8,29.4-105.6-6.1 C635.1,742.4,603,587.9,603,587.9z"
        />
        <polygon
          className="st0"
          points="748,757.7 795,752.1 830.6,758.2 910.2,787.5 877.6,803.3 "
        />
        <path
          className="st1"
          d="M654.9,713.7c-1.8,14.8-4.2,27.2-15.7,42.5"
        />
        <path
          className="st1"
          d="M748,757.7l-25.4,10.7c-20.8,8.9-44.9-0.7-53.8-21.6c0,0,0-0.1,0-0.1l-69.5-171.2"
        />
        <path
          className="st1"
          d="M560.2,479.4l-45.1-111.2c-8.9-20.9,0.7-45.1,21.5-54.3l74.2-31.8"
        />
        <path
          className="st2"
          d="M928.8,735.8l-210.4,25.4c-22.5,2.6-42.8-13.6-45.4-36.1c0,0,0,0,0,0l-62.8-429.9c-2.6-22.6,13.5-43.1,36.1-46
          l210.4-25.3c22.5-2.6,42.8,13.5,45.4,36c0,0,0,0.1,0,0.1l62.8,429.9C967.5,712.5,951.4,733,928.8,735.8"
        />
        <path
          className="st1"
          d="M795,752.1l-76.6,9.1c-22.5,2.6-42.8-13.6-45.4-36.1c0,0,0,0,0,0l-30.7-210.3"
        />
        <path
          className="st1"
          d="M632.9,450.4l-22.6-155.1c-2.6-22.6,13.5-43.1,36.1-46l49-5.9"
        />
        <path
          className="st2"
          d="M931.5,747.8l-203.1,2c-22.6-0.6-40.5-19.5-39.8-42.2c0,0,0-0.1,0-0.1l4.6-452.4c0.7-22.7,19.5-40.7,42.3-40.3
          l211.9,5c22.6,0.6,40.5,19.5,39.8,42.2c0,0,0,0.1,0,0.1l-13.4,445.4C973.1,730.2,954.3,748.1,931.5,747.8"
        />
        <path
          className="st1"
          d="M776.5,749.3l-48,0.5c-22.6-0.6-40.5-19.5-39.8-42.2c0,0,0-0.1,0-0.1l4.6-452.4c0.7-22.7,19.5-40.7,42.3-40.3
          l211.9,5c18.5,0.4,34.8,17,39.4,34.1"
        />
        <path
          className="st2"
          d="M957.3,778.8l-204-33.1c-22.3-4.8-37.9-27-34.8-49.4l70.7-443.1c3.2-22.4,24-36.7,46.2-31.9l209,45.1
          c22.3,4.8,37.9,27,34.7,49.4l-70,422.1C1006.1,760.2,979.5,783.6,957.3,778.8"
        />
        <path
          className="st1"
          d="M827.8,757.2l-74.6-11.5c-22.3-4.8-37.9-27-34.8-49.4l70.7-443.1c3.2-22.4,24-36.7,46.2-31.9l209,45.1
          c20.1,4.3,34.8,22.8,35.1,42.8l-0.1,3.5"
        />
        <path
          className="st2"
          d="M941.5,799.1l-183.3-67.5c-19.8-9.7-29-34.5-20.3-55L908,273.8c8.7-20.5,32-29.4,51.9-19.7l186.4,91.2
          c19.9,9.7,29,34.5,20.3,55L993.4,779.4C984.7,800,961.4,808.8,941.5,799.1"
        />
        <path
          className="st1"
          d="M941.5,799.1l-183.3-67.5c-19.8-9.7-29-34.5-20.3-55L908,273.8c8.7-20.5,32-29.4,51.9-19.7l186.4,91.2
          c19.9,9.7,29,34.5,20.3,55l-161.4,353.1l-11.8,25.9C984.7,800,961.3,808.8,941.5,799.1z"
        />
        <line className="st1" x1="935" y1="357.4" x2="1079.6" y2="428.1" />
        <line className="st1" x1="916.6" y1="398.1" x2="1061.3" y2="468.8" />
        <line className="st1" x1="898.3" y1="438.8" x2="1042.9" y2="509.5" />
        <line className="st1" x1="879.9" y1="479.4" x2="1024.5" y2="550.2" />
        <line className="st1" x1="954.7" y1="311.7" x2="1015.6" y2="340.2" />
        <line className="st1" x1="835" y1="333.8" x2="877.7" y2="344.5" />
        <line className="st1" x1="826.2" y1="377.6" x2="857.3" y2="385.6" />
        <line className="st1" x1="817.5" y1="421.4" x2="838.2" y2="427" />
        <line className="st1" x1="808.7" y1="465.1" x2="825.2" y2="469.7" />
        <line className="st1" x1="846.4" y1="284.9" x2="898.2" y2="297.1" />
        <line className="st1" x1="743.5" y1="325.8" x2="776.5" y2="326.3" />
        <line className="st1" x1="744.5" y1="370.5" x2="770.5" y2="370.9" />
        <line className="st1" x1="745.5" y1="415.1" x2="762" y2="416" />
        <line className="st1" x1="746.6" y1="459.7" x2="756.2" y2="460.1" />
        <line className="st1" x1="743.9" y1="275.6" x2="783.6" y2="276.6" />
        <line className="st1" x1="658.2" y1="353.9" x2="691" y2="349.8" />
        <line className="st1" x1="665.5" y1="397.9" x2="691.3" y2="394.7" />
        <line className="st1" x1="672.8" y1="442" x2="689.2" y2="440.6" />
        <line className="st1" x1="680" y1="486" x2="690.2" y2="484.7" />
        <line className="st1" x1="651.7" y1="304.1" x2="691.1" y2="299.5" />
        <line className="st1" x1="597.4" y1="408" x2="623.1" y2="398.2" />
        <line className="st1" x1="615.6" y1="448.8" x2="630.5" y2="442.6" />
        <line className="st1" x1="578.4" y1="361.5" x2="615.4" y2="347.1" />
        <path
          className="st1"
          d="M1015.7,729.7l66.9-34.8c8.7-4.2,15.1-12.1,17.4-21.4v-0.1c3.9-15.9-7.3-36.7-17.6-34.4"
        />
        <path
          className="st1"
          d="M678.4,1007.1l9.4-29.8c2.9-9.3,6.9-18.2,11.9-26.5l0.1-0.2c41.8-69.5,135.6-130.3,210.3-161.9"
        />
        <path
          className="st1"
          d="M405,999.2l20.2-36.6c33.4-58.6,46.7-126.5,37.9-193.4c-5.8-43.3-7.8-90.2,4.9-114.4
          c9.7-18.5,36.3-97.4,49.1-136c4.5-13.6,14.7-24.7,27.8-30.4l101.8-44.1c6.3-2.7,13.7,0.2,16.4,6.5c0.3,0.7,0.6,1.5,0.7,2.2
          c0,0,13.1,35.7-21.5,61.7c-51.8,38.9-45.3,85.6-45.3,85.6"
        />
        <path
          className="st1"
          d="M1089.2,572.1l4.7-1.9c24.9-5.9,46.7,39.8-1.9,64.4c-17.6,8.9-37.8,11.9-37.6,11.3"
        />
        <path
          className="st1"
          d="M1092.1,563.5c10.1-3.6,58-34.7,29-62.5"
        />
      </Svg>
    );
  }
}
