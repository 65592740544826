import React from "react";
import styled from "styled-components";
import firebase from 'firebase/app';
import 'firebase/database';
import isEmail from 'sane-email-validation';

import { colors } from "../vars/globalVars";

import RightArrow from "../assets/RightArrow";
import CloserLogoSubscribe from "../assets/CloserLogoSubscribe";

const Container = styled.section`
  height: 100%;
  width: 100%;
  position: absolute;
  display: ${props => props.isMobile ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  z-index: 7;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const Title = styled.h1`
  color: ${colors.brown};
  font-size: 46px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 10px;

  @media (min-width: 1201px) {
    font-size: 58px;
  }
`;

const Subtitle = styled.h1`
  color: ${colors.brown};
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 10px;

  @media (min-width: 1201px) {
    font-size: 26px;
  }
`;


const Logo = styled.div`
  margin-bottom: 50px;
`;

const EmailContainer = styled.div`
  width:100%;
  max-width: 450px;
`;

const Email = styled.div`
  width: 100%;
  max-width: 450px;
  display: ${props => props.isMobile ? 'flex' : 'none'};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-bottom: 1px solid ${colors.brown};

  input {
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    line-height: 35px;
    padding: 0;
    color: ${colors.brown};

    &::placeholder {
      color: ${colors.brown};
      opacity: 0.6;
    }
  }

  button {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    color: ${colors.brown};
    font-size: 15px;
    font-weight: 500;
    line-height: 35px;
    border: none;
    padding: 0;

    outline: none;
    cursor: pointer;
  }
`;

const ValidationError = styled.p`
  color: ${colors.darkTomato};
  text-align: left;
  font-size: 12px;
`;


const firebaseConfig = {
  apiKey: "AIzaSyAgtxcC-gbGwTwY3TkQwRUEOcvAwlukiKY",
  authDomain: "test-c3f6d.firebaseapp.com",
  databaseURL: "https://test-c3f6d.firebaseio.com",
  projectId: "test-c3f6d",
  storageBucket: "test-c3f6d.appspot.com",
  messagingSenderId: "932620752038"
};

export default class Shop extends React.Component {

  state = {
    validationError: false,
    email: '',
    hasSubscribed: false,
  };

  updateEmail = email => this.setState({ email });

  saveEmail = () => {
    const { email } = this.state;
    if (isEmail(email)) {
      this.setState({ validationError: false });
      firebase.initializeApp(firebaseConfig);
      firebase.database().ref('/emails').push(email, (error) => {
        if (error) {
          console.log(error);
        } else {
          this.setState({ hasSubscribed: true });
          firebase.database().goOffline();
        }
      });
    } else {
      this.setState({ validationError: true });
    }
  };

  render() {
    const { email, hasSubscribed, validationError } = this.state;
    const { isMobile } = this.props;
    return (
      <Container id="StayInTouch" isMobile={isMobile}>
        <Logo id="StayInTouchLogo">
          <CloserLogoSubscribe />
        </Logo>
        <Title id="StayInTouchTitle">
          { hasSubscribed ? 'Thank you!' : 'Stay in touch' }
        </Title>
        <Subtitle id="StayInTouchSubtitle">
          { hasSubscribed ? "" : ''}
        </Subtitle>
        {
          !hasSubscribed &&
          <EmailContainer>
            <Email id="StayInTouchEmail" isMobile={isMobile}>
              <input
                type="email"
                value={email}
                onChange={e => this.updateEmail(e.target.value)}
                placeholder="Your email address"
              />
              <button onClick={this.saveEmail} type="button">Subscribe <RightArrow color={colors.brown} /></button>
            </Email>
            {
              validationError &&
              <ValidationError>Please enter a valid email address</ValidationError>
            }
          </EmailContainer>
        }

      </Container>
    );
  }
}
