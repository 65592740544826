import React, { Component } from "react";
import { TimelineMax, TweenMax, Power3 } from "gsap";
import TextPlugin from "gsap/TextPlugin";

import styled from "styled-components";

import {
  t1_intro,
  t2_cardsShowcase,
  t3_cardTricks,
  t4_cardTypes,
  t5_gallery,
  t6_howToPlay,
  t7_stairs,
  t8_shop
} from "./tweens";

// Desktop components
import { GlobalStyle } from "./core/globalStyles";
import Header from "./components/Absolute/Header";
import Footer from "./components/Absolute/Footer";
import SideMenu from "./components/Absolute/SideMenu";

import Background1 from "./components/Absolute/Background1";
import Background2 from "./components/Absolute/Background2";
import Background3 from "./components/Absolute/Background3";


import LandingPage from "./components/LandingPage";
import FamiliyPage from "./components/FamiliyPage";
import GameDetails from "./components/GameDetails";
import GameDetailCards from "./components/GameDetailCards";
import Cards from "./components/Cards";
import CardTypes from "./components/CardTypes";
import Gallery from "./components/Gallery";
import HowToPlay from "./components/HowToPlay";
import ClimbingStairs from "./components/ClimbingStairs";
import Shop from "./components/Shop";
import StayInTouch from "./components/StayInTouch";


// eslint-disable-next-line
const plugins = [TextPlugin];

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuTween: new TimelineMax({ paused: true }),
      masterTween: new TimelineMax({ paused: false })
    };
  }

  componentDidMount() {
    // Set defaultEase
    TweenMax.defaultEase = Power3.easeOut;

    this.createMenuTween();
    this.bootTweens();
  }

  createMenuTween = () => {
    let { menuTween } = this.state;

    menuTween.to("#SideMenu", 0.5, {
      right: "0%"
    });
  };

  registerEventListeners = () => {
    let { masterTween } = this.state;

    // destroy the scene and reset the pin and tween on resize
    window.addEventListener("resize", () => this.scene.destroy(true));

    // Desktop devices

    // Bind wheel/scroll event listener for controlling the animation
    window.addEventListener("wheel", e => {
      if (e.deltaY > 0) {
        masterTween.play();
      } else {
        // The timeline shouldn't be able to go before 3.2 (after intro animation)
        masterTween.time() > 3.2 && masterTween.reverse();
      }
    });

    // Touch devices

    let firstTouchX = undefined;
    let firstTouchY = undefined;

    // Detecting up / down / left / right swipe for tablet
    // up / right => play()
    // down / left => reverse()
    window.addEventListener("touchmove", e => {
      e.preventDefault();
      var nextTouchX = e.touches[0].clientX;
      var nextTouchY = e.touches[0].clientY;

      var XDistance = Math.abs(firstTouchX - nextTouchX);
      var YDistance = Math.abs(firstTouchY - nextTouchY);

      if (XDistance > YDistance && XDistance > 150) {
        if (nextTouchX > firstTouchX) {
          masterTween.reverse();
        } else {
          masterTween.play();
        }
      } else if (YDistance > 150) {
        if (nextTouchY > firstTouchY) {
          masterTween.reverse();
        } else {
          masterTween.play();
        }
      }
    });

    window.addEventListener("touchstart", e => {
      firstTouchX = e.touches[0].clientX;
      firstTouchY = e.touches[0].clientY;
    });
  };

  bootTweens = () => {
    // TODO: Currently here to fix ipad scroll issue, should take this out
    var controller = new window.ScrollMagic.Controller();

    this.scene = new window.ScrollMagic.Scene({
      triggerElement: "#ContainerPinElement",
      triggerHook: "onLeave",
      duration: "0%"
    })
      .setPin("#ContainerPinElement")
      .addTo(controller);

    this.registerEventListeners();

    let { masterTween } = this.state;

    // 1. Intro - Card Swivel
    masterTween = t1_intro(masterTween);
    // 2. Cards Showcase - Blue / Pink bg, card details
    masterTween = t2_cardsShowcase(masterTween);
    // 3. Card Tricks - Slide cards to the left, bring in RED cards
    masterTween = t3_cardTricks(masterTween);
    // 4. Card Types - Show the types of cards
    masterTween = t4_cardTypes(masterTween);
    // 5. Full Page Numbers - Show deck contents
    masterTween = t5_gallery(masterTween);
    // 6. How to Play - Show walkthrough images
    masterTween = t6_howToPlay(masterTween);
    // 7. Climbing stairs - Show the guy climbing the stairs
    masterTween = t7_stairs(masterTween);
    // 8. Shop - Show shop screens
    masterTween = t8_shop(masterTween);

    // Update state based on masterTween updates
    this.setState({ masterTween });
  };

  openMenu = () => {
    const { menuTween } = this.state;
    menuTween.play();
  };

  closeMenu = () => {
    const { menuTween } = this.state;
    menuTween.reverse();
  };

  playTimelineAtLabel = label => {
    const { masterTween } = this.state;

    masterTween.pause();
    masterTween.seek(label);
  };

  render() {
    const { masterTween } = this.state;

    return (
      <Container id="ContainerPinElement">
        <GlobalStyle />
        <Background1 />
        <Background2 />
        <Background3 />
        <LandingPage />
        <FamiliyPage />
        <GameDetails />
        <GameDetailCards />
        <Cards masterTween={masterTween} id="Cards" />
        <CardTypes />
        <Gallery />
        <HowToPlay navigateTo={this.playTimelineAtLabel} />
        <ClimbingStairs />
        <Shop navigateTo={this.playTimelineAtLabel} />
        <StayInTouch />
        <Header
          navigateTo={this.playTimelineAtLabel}
          openMenu={this.openMenu}
        />
        <Footer />
        <SideMenu
          navigateTo={this.playTimelineAtLabel}
          closeMenu={this.closeMenu}
        />
      </Container>
    );
  }
}

export default App;
