import { colors } from "../vars/globalVars";

const isSmallRes = window.innerWidth < 768;

export default tl => {
  tl

    // Pre-animation prep
    // ------------------
    .set("#Shop", { display: 'flex' })

    // 1. Shop Now
    // -----------
    .to("#Background2", 0.3, { backgroundColor: colors.stairsPurple, x: isSmallRes ? "100%" : "50%" }, "-=0.3")

    // Update active menu classes
    // --------------------------
    .to("#HeaderMenu .Menu-Item", 0, { className: "-=active" }, "-=0.7")
    .to("#HeaderMenu .Shop", 0, { className: "+=active" })

    .from("#ShopTitle", 0.5, { y: 100, opacity: 0 })
    .from("#ShopSubtitle", 0.5, { y: 100, opacity: 0 }, "-=0.3")
    .from("#ShopBuyNow", 0.5, { y: 100, opacity: 0 }, "-=0.3")
    .from("#DeckImg", 1, { y: 100, opacity: 0 }, "-=0.5")

    // Menu label for navigation
    // -------------------------
    .addLabel("Shop")

    // Pause (tween)
    // -------------
    .addPause()

    .to("#ShopTitle", 1, { y: -100, opacity: 0 })
    .to("#ShopSubtitle", 1, { y: -100, opacity: 0 }, "-=1")
    .to("#ShopBuyNow", 1, { y: -100, opacity: 0 }, "-=1")
    .to("#DeckImg", 1, { y: -100, opacity: 0 }, "-=1")
    //
    // // SWITCH BACKGROUND START
    //
    // // Step 1
    // // ------
    // .to("#Background3", 0, { x: "100%", backgroundColor: colors.cream })
    // .to("#Background3", 0.6, { x: "0%" })
    //
    // // Step 2
    // // ------
    // .to("#Header", 0.7, { color: colors.tomato, borderColor: colors.tomato }, "-=0.7")
    // .to(".CloserLogo path", 0.7, { attr: { fill: colors.tomato } }, "-=0.7")
    // .to("#HeaderBuyButton svg g", 0.7, { attr: { stroke: colors.tomato } }, "-=0.7")
    // .to("#Footer", 0.7, { color: colors.tomato }, "-=0.7")
    //
    //
    // // Step 3
    // // ------
    // .to("#Background1", 0, { backgroundColor: colors.cream })
    // .to("#Background2", 0, { backgroundColor: colors.darkCream, x: "100%" })
    // .to("#Background3", 0, { backgroundColor: colors.lighterTeal, x: "100%" });

  if (!isSmallRes) {
    tl.to("#Background2", 0.4, { x: "50%" });
  }

  tl
    // Pre-animation prep
    // ------------------
    // .set("#Merch", { display: 'flex' })
    //
    // // SWITCH BACKGROUND END
    //
    // // 2. Merch
    // .from("#MerchTitle", 0.5, { y: 100, opacity: 0 })
    // .from("#MerchSubtitle", 0.5, { y: 100, opacity: 0 }, "-=0.3")
    // .from("#MerchBuyNow", 0.5, { y: 100, opacity: 0 }, "-=0.3")
    // .from("#MerchImg", 1, { y: isSmallRes ? 100 : -100, opacity: 0 }, "-=0.5")

    // Pause (tween)
    //.addPause()

    .to("#ShopTitle", 1, { y: -100, opacity: 0 })
    .to("#ShopSubtitle", 1, { y: -100, opacity: 0 }, "-=1")
    .to("#ShopBuyNow", 1, { y: -100, opacity: 0 }, "-=1")
    .to("#ShopImg", 1, { y: -100, opacity: 0 }, "-=1")

    // SWITCH BACKGROUND START

    // Step 1
    .to("#Background3", 0, { x: "100%", backgroundColor: colors.vanilla })
    .to("#Background3", 0.6, { x: "0%" })

    // Step 2
    .to("#Header", 0.7, { color: colors.brown, borderColor: colors.brown }, "-=1")
    .to(".CloserLogo path", 0.7, { attr: { fill: colors.brown } }, "-=0.7")
    .to("#HeaderBuyButton svg g", 0.7, { attr: { stroke: colors.brown } }, "-=0.7")
    .to("#Footer", 0.7, { color: colors.brown }, "-=1")

    // Step 3
    .to("#Background1", 0, { backgroundColor: colors.vanilla })
    .to("#Background2", 0, { backgroundColor: colors.darkVanilla, x: "100%" })
    .to("#Background3", 0, { x: "100%" });

  if (!isSmallRes) {
    tl.to("#Background2", 0.4, { x: "50%" });
  }

  tl
    // Pre-animation prep
    // ------------------
    .set("#Wallpapers", { display: 'flex' })

    // SWITCH BACKGROUND END

    // 3. Merch
    .from("#WallpapersTitle", 0.5, { y: 100, opacity: 0 })
    .from("#WallpapersSubtitle", 0.5, { y: 100, opacity: 0 }, "-=0.3")
    .from("#WallpapersBuyNow", 0.5, { y: 100, opacity: 0 }, "-=0.3")
    .from("#WallpapersImg", 1, { y: 100, opacity: 0 }, "-=0.5")

    // Pause (scroll)
    // .to("#WallpapersTitle", 1, {})

    // Pause (tween)
    .addPause()

    .to("#WallpapersTitle", 1, { y: -100, opacity: 0 })
    .to("#WallpapersSubtitle", 1, { y: -100, opacity: 0 }, "-=1")
    .to("#WallpapersBuyNow", 1, { y: -100, opacity: 0 }, "-=1")
    .to("#WallpapersImg", 1, { y: -100, opacity: 0 }, "-=1")

    // Pre-animation prep
    // ------------------
    .set("#StayInTouch", { display: 'flex' })

    // SWITCH LAST BACKGROUND

    .to("#Background2", 1, { x: "100%" })

    // Pre-animation prep
    // Needs to switch from 'none' to 'flex' right before animation starts
    .to("#StayInTouchEmail", 0, { display: "flex" })

    // 3. GetCloser

    // Update active menu classes
    // --------------------------
    .to("#HeaderMenu .Menu-Item", 0, { className: "-=active" }, "-=0.7")
    .to("#HeaderMenu .GetCloser", 0.7, { className: "+=active" })

    .from("#StayInTouchLogo", 1, { y: 100, opacity: 0 })
    .from("#StayInTouchTitle", 1, { y: 100, opacity: 0 }, "-=0.5")
    .from("#StayInTouchSubtitle", 1, { y: 100, opacity: 0 }, "-=0.5")
    .from("#StayInTouchEmail", 1, { y: 100, opacity: 0 }, "-=0.5")

    // Menu label for navigation
    .addLabel("GetCloser");

  return tl;
};
