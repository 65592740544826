import React from "react";
import styled from "styled-components";

import { colors } from "../vars/globalVars";

import MouseScroll from "../assets/MouseScroll";
import background from "../assets/img/13_Landscape_Illustration_Home-02.png";

const Container = styled.section`
  height: 100%;
  width: 100%;
  background-color: ${colors.skyBlue};
  display: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0 20px;
  transform: translateY(-50%);
  position: absolute;
  top: -100%;
  overflow: hidden;
 

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const Hero = styled.h1`
  color: ${colors.white};
  font-size: 40px;
  font-weight: 500;
  line-height: 36px;
  align-self: flex-end;

  @media (min-width: 768px) {
    width: 60%;
    font-size: 53px;
    line-height: 53px;
    position: absolute;
    bottom: 20%;
    right: 10%;
  }

  @media (min-width: 1201px) {
    position: absolute;
    width: 50%;
    font-size: 80px;
    line-height: 70px;
    right: 15%;
  }
`;

export default class FamilyPage extends React.Component {
  render() {
    return (
      <Container
        id="FamilyPage"
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "left -250px",
          backgroundRepeat: "no-repeat"
        }}>
        <Hero id="FamilyPageHero">The game where you win each other.</Hero>
        <MouseScroll />
      </Container>
    );
  }
}
