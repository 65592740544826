import { colors } from "../vars/globalVars";

const isSmallRes = window.innerWidth < 768;

export default tl => {

  if (isSmallRes) {
    tl
      .from("#GameDetails", 0.7, { top: "-100%", opacity: 0 }, "-=0.3")
      .to("#FamilyPage", 0.7, {
        top: "55%",
        transform: "translateY(0)"
      }, "-=1")

      // Switch FamilyCard with Card
      // ---------------------------
      //.to("#GameDetailCards", 0, { display: "flex", opacity: 1 })
      .to("#FamilyPage", 0, { display: "none" })
      .to("#GDCard1", 1, { display: "flex", opacity: 1,
        onComplete: () => {
          const video = document.querySelector("#GDCard2 video");
          if (video) {
            return video.play();
          }
        },
        onReverseComplete: () => {
          const video = document.querySelector("#GDCard2 video");
          if (video) {
            return video.pause();
          }
        }
      })

      // Menu label for mobile navigation
      // -------------------------
      .addLabel("TheGame")

      // Pause (tween)
      // ------------
      .addPause()

      // Slide to action card
      // --------------------
      .to("#GameDetails", 1, { height: "-150px",  display: "none", opacity: 0 })
      .to("#GameDetailCards", 0.7, { display: "flex", opacity: 1, top: 0, height: "100%" }, "-=0.7")
      .to("#GDCard1", 1, { justifyContent: "center" }, "-=1")
      .to("#GDCard1Title", 0.5, { display: "block", opacity: 1 }, "-=1")
      .to("#GDCard1Description", 0.5, { display: "block", opacity: 1 }, "-=1")

      // Menu label for mobile navigation
      // ---------------------------------
      .addLabel("Cards")

      // Pause (tween)
      // ------------
      .addPause()

      // Slide out action card
      // ----------------------
      .to("#GDCard1", 0.7, { top: -300, display: "none", opacity: 0,
        onComplete: () => {
          const video = document.querySelector("#GDCard1 video");
          if (video) {
            return video.pause();
          }
        },
        onReverseComplete: () => {
          const video = document.querySelector("#GDCard1 video");
          if (video) {
            return video.play();
          }
        }
      })

      // Slide in pink background
      // -------------------------
      .from("#Background2", 1, { backgroundColor: colors.offWhite, y: "100%" }, "-=0.5")

      // Slide to question card
      // ----------------------
      .fromTo("#GDCard2", 0.7, {top: 300}, { top: 0, height: "100%", display: "flex", opacity: 1,
        onComplete: () => {
          const video = document.querySelector("#GDCard2 video");
          if (video) {
            return video.play();
          }
        },
        onReverseComplete: () => {
          const video = document.querySelector("#GDCard2 video");
          if (video) {
            return video.pause();
          }
        }
      }, "-=0.7")
      .to("#Background2", 0.5, { backgroundColor: colors.offWhite }, "-=0.5")
      .to("#GDCard2Title", 0.5, { display: "block", opacity: 1 }, "-=0.7")
      .to("#GDCard2Description", 0.5, { display: "block", opacity: 1 }, "-=0.7")
      .to(".CloserLogo path", 0.5, { attr: { fill: colors.pink } }, "-=0.7")
      .to("#Header", 0.5, { color: colors.pink, borderColor: colors.pink }, "-=0.7")
      .to("#HeaderBuyButton svg g", 0.7, { attr: { stroke: colors.pink } }, "-=0.7")
      .to("#Footer", 0.5, { color: colors.pink }, "-=0.7")

      // Pause (tween)
      // ------------
      .addPause()

      // Slide out detail cards
      // ----------------------
      .to("#GameDetailCards", 1, { top: "-100%", display: "none", opacity: 0,
        onComplete: () => {
          const video = document.querySelector("#GDCard2 video");
          if (video) {
            return video.pause();
          }
        },
        onReverseComplete: () => {
          const video = document.querySelector("#GDCard2 video");
          if (video) {
            return video.play();
          }
        }
      })

      // Hide the desktop card details
      // ---------------------------
      .to("#Card1Title, #Card2Title, #Card1Description, #Card2Description", 0,
        { display: "none", opacity: 0, y: "-100%" })

      // Pre-animation prep
      // ------------------
      .set("#Cards", { display: 'flex' });
  } else {
    tl
      .from("#GameDetails", 0.3, { top: "150px", opacity: 0 }, "-=0.3")
      .to("#FamilyPage", 0, { display: "none" })

      // Pre-animation prep
      // ------------------
      .set("#Cards", { display: 'flex' })

      // Switch FamilyCard with Card
      // ---------------------------
      .to("#CardsGallery", 0, { display: "flex", opacity: 1 })
      .to("#FamilyPage", 0, { display: "none" })
      .to("#Card1", 0.7, { display: "block", opacity: 1, position: "absolute" })

      // Menu desktop label for desktop navigation
      // -----------------------------------------
      .addLabel("TheGame")

      // Pause (tween)
      // ------------
      .addPause()

      // Slide in pink background
      // -------------------------
      .set("#Background2", { backgroundColor: colors.offWhite, x: "100%" })

      // Pre-animation prep
      // -------------------
      .to("#CardsGallery .Card:not(:first-child)", 0, { position: "absolute", left: "200%" })

      // Slide the pink card and rest of the content
      // -------------------------------------------
      .to("#Background2", 1, { x: "50%" })

      // Update menu colors
      // ------------------
      .to("#Header", 0.7, { color: colors.pink, borderColor: colors.pink }, "-=1")
      //.to(".CloserLogo path", 0.7, { attr: { fill: colors.darkBlue } }, "-=1")
      .to("#HeaderBuyButton svg g", 0.7, { attr: { stroke: colors.pink } }, "-=1")
      .to("#Footer", 0.7, { color: colors.skyBlue }, "-=1")

      // Move family card to left, slide in strings card
      // -----------------------------------------------
      .to("#GameDetails", 0.5, { y: -100, opacity: 0 }, "-=1")
      .to("#Card1", 0.7, {
        left: "25%",
        transform: "translateX(-50%)",
        onComplete: () => {
          const video = document.querySelector("#Card1 video");
          if (video) {
            if (video.paused) {
              video.play();
            } else {
              video.pause();
            }
          }
        }
      }, "-=1")
      .to("#Card2", 0.7, {
        left: "75%",
        transform: "translateX(-50%)",
        display: "block",
        opacity: 1,
        onComplete: () => {
          const video = document.querySelector("#Card2 video");
          if (video) {
            if (video.paused) {
              video.play();
            } else {
              video.pause();
            }
          }
        }
      }, "-=0.7")

      // Update active menu classes
      // --------------------------
      .to("#HeaderMenu .Menu-Item", 0, { className: "-=active" }, "-=0.7")
      .to("#HeaderMenu .Cards", 0, { className: "+=active" })


      // Slide in the card details
      // --------------------------
      .from("#Card1Title", 0.5, { display: "none", opacity: 0, y: "100%" })
      .from("#Card2Title", 0.5, { display: "none", opacity: 0, y: "100%" }, "-=0.5")
      .from("#Card1Description", 0.5, { display: "none", opacity: 0, y: "100%" })
      .from("#Card2Description", 0.5, { display: "none", opacity: 0, y: "100%" }, "-=0.5")

      // Menu label for desktop navigation
      // ---------------------------------
      .addLabel("Cards")

      // Pause (tween)
      .addPause()

      // Slide out the card details
      // ---------------------------
      .to("#Card1Title", 0.5, { display: "none", opacity: 0, y: "-100%" })
      .to("#Card2Title", 0.5, { display: "none", opacity: 0, y: "-100%" }, "-=0.5")
      .to("#Card1Description", 0.5, { display: "none", opacity: 0, y: "-100%" })
      .to("#Card2Description", 0.5, { display: "none", opacity: 0, y: "-100%" }, "-=0.5");
  }

  return tl;
};
