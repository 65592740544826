import React from "react";

import { colors } from "../vars/globalVars";

export default class MCFunny extends React.Component {
  render() {
    return (
      <svg width={50} height={62} xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <path id="a" d="M0 .367h43.819V58H0z" />
          <path id="c" d="M.662.425h35.157V58H.662z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            d="M21.912 19.831c-.56 0-1.127.052-1.695.161a8.924 8.924 0 0 0-5.743 3.781 8.924 8.924 0 0 0-1.389 6.736 8.93 8.93 0 0 0 3.781 5.744 8.932 8.932 0 0 0 6.735 1.388 8.93 8.93 0 0 0 5.745-3.782 8.93 8.93 0 0 0 1.387-6.735c-.825-4.296-4.6-7.293-8.822-7.293m-.024 20.004c-2.184 0-4.314-.65-6.16-1.9a10.942 10.942 0 0 1-4.636-7.043c-1.144-5.965 2.777-11.749 8.743-12.894 5.959-1.146 11.75 2.778 12.893 8.743A10.943 10.943 0 0 1 31.026 35a10.938 10.938 0 0 1-7.043 4.636c-.698.133-1.4.2-2.096.2"
            fill={colors.darkTomato}
          />
          <path
            d="M18.8 31.218a3.606 3.606 0 0 0 3.913 1.918 3.6 3.6 0 0 0 2.925-3.23L18.8 31.218zm3.212 3.985a5.588 5.588 0 0 1-5.118-3.338 1.85 1.85 0 0 1 .052-1.615 1.84 1.84 0 0 1 1.293-.962l7.208-1.383a1.85 1.85 0 0 1 2.2 1.898A5.593 5.593 0 0 1 23.09 35.1c-.362.07-.722.104-1.078.104zM15.732 28.046a2.23 2.23 0 0 1 4.393-.776M23.057 26.754a2.23 2.23 0 0 1 4.393-.775"
            fill={colors.darkTomato}
          />
          <g transform="translate(0 -.367)">
            <mask id="b" fill={colors.white}>
              <use xlinkHref="#a" />
            </mask>
            <path
              d="M31.245 2.367c-.152 0-.306.014-.46.042L4.075 7.33a2.535 2.535 0 0 0-2.033 2.95l8.043 43.645a2.517 2.517 0 0 0 1.053 1.627 2.515 2.515 0 0 0 1.895.405l26.71-4.922h.002a2.51 2.51 0 0 0 1.626-1.053c.383-.557.526-1.23.404-1.895L33.736 4.44a2.535 2.535 0 0 0-2.49-2.073M12.566 58a4.506 4.506 0 0 1-2.561-.8 4.504 4.504 0 0 1-1.886-2.912L.075 10.642a4.538 4.538 0 0 1 3.636-5.279L30.422.443a4.539 4.539 0 0 1 5.28 3.634l8.04 43.646a4.498 4.498 0 0 1-.724 3.393 4.497 4.497 0 0 1-2.913 1.886l-26.71 4.922a4.68 4.68 0 0 1-.83.076"
              fill={colors.darkTomato}
              mask="url(#b)"
            />
          </g>
          <g transform="translate(14 3.633)">
            <mask id="d" fill={colors.white}>
              <use xlinkHref="#c" />
            </mask>
            <path
              d="M35.743 47.724L27.702 4.078c-.367-1.99-2-3.412-3.903-3.653l.394 2.136c.773.316 1.38.999 1.542 1.88l8.04 43.646a2.52 2.52 0 0 1-.404 1.896c-.383.556-.961.93-1.627 1.053l-26.711 4.92a2.512 2.512 0 0 1-1.894-.404c-.101-.07-.188-.152-.277-.233l-2.2.406A4.491 4.491 0 0 0 2.004 57.2a4.505 4.505 0 0 0 3.392.724l26.71-4.922a4.492 4.492 0 0 0 2.912-1.885 4.5 4.5 0 0 0 .725-3.393"
              fill={colors.darkTomato}
              mask="url(#d)"
            />
          </g>
          <g opacity={0.4} fill={colors.darkTomato}>
            <path d="M40.066 21.884a16.256 16.256 0 0 1 1.571 2.035l-.21-1.15A16.842 16.842 0 0 0 39.902 21l.163.885zM44.118 37.383l-.215-1.168c-.18.846-.433 1.67-.742 2.47l.166.897c.312-.71.577-1.446.791-2.199M39.262 17.517c.485.36.957.74 1.407 1.143l-.148-.807a20.145 20.145 0 0 0-1.393-1.062l.134.726zM44.102 43.79c.327-.483.639-.977.922-1.487l-.149-.812c-.276.539-.583 1.06-.907 1.569l.134.73zM38.584 13.84c.455.256.91.515 1.345.803l-.126-.684a22.915 22.915 0 0 0-1.337-.763l.118.643zM44.726 47.176c.014.075.008.149.019.224.342-.395.682-.791.996-1.21l-.124-.673c-.304.424-.637.826-.971 1.227l.08.432zM46.269 49.057c-.823.933-1.74 1.782-2.7 2.583a24.345 24.345 0 0 1-2.28 1.691c-.945.62-1.921 1.2-2.96 1.687l1.709-.315a24.971 24.971 0 0 0 6.345-5.025l-.114-.621zM39.16 10.471c-.426-.215-.866-.404-1.305-.594l.108.586c.444.201.883.41 1.314.636l-.116-.628zM21.715 57.041c.874.224 1.757.42 2.66.548l1.695-.312a24.346 24.346 0 0 1-3.157-.457l-1.198.221z" />
          </g>
        </g>
      </svg>
    );
  }
}
